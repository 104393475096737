const defaultTheme = {
  backgroundColor: "#ffffff",
  menuBackgroundColor: "#f5f6fa",
  modalColor: "#ffffff",
  modalFooterColor: "#f5f6fa",
  textColor: "#000000",
  mainColor: "#0984e3",
  mainColorHover: "#076bb8",
  buttonColor: "#ffffff",
  dangerColor: "#ed4c67",
  dangerColorHover: "#b53471",
  playerColor: "#0984e3",
  playerTextColor: "#ffffff",
  tagColor: "#777777",
  bulletColor: "#f5f6fa",
  borderColor: "#f1f2f6",
  stationItemBackgroundColor: "#f1f2f6",
  stationItemShadowColor: "#333333",
  // slideshowBackground: "#0984e377", // "linear-gradient(#000000 0%, #00000000 100%)",
  slideshowBackground:
    "linear-gradient(90deg, #0984e377 0%, #0984e333 50%, #0984e377 100%)", // "linear-gradient(#000000 0%, #00000000 100%)",
};

const darkTheme = {
  backgroundColor: "#2c3e50",
  menuBackgroundColor: "#34495e",
  modalColor: "#34495e",
  modalFooterColor: "#2c3e50",
  textColor: "#ffffff",
  mainColor: "#f5f6fa",
  mainColorHover: "#076bb8",
  buttonColor: "#000000",
  dangerColor: "#ed4c67",
  dangerColorHover: "#b53471",
  playerColor: "#34495e",
  playerTextColor: "#ffffff",
  tagColor: "#ffffff",
  bulletColor: "#34495e",
  borderColor: "#333333",
  stationItemBackgroundColor: "#34495e",
  stationItemShadowColor: "#cccccc",
  badgeColor: "#0984e3",
  slideshowBackground: "#000000", // "linear-gradient(#000000 0%, #00000000 100%)",
  inputBackgroundColor: "#2c3e50",
  inputBorderColor: "#2c3e50",
  inputTextColor: "#ffffff",
};

const redTheme = {
  backgroundColor: "#ffffff",
  menuBackgroundColor: "#f5f6fa",
  modalColor: "#ffffff",
  modalFooterColor: "#f5f6fa",
  textColor: "#000000",
  mainColor: "#ed4c67",
  mainColorHover: "#d63031",
  buttonColor: "#ffffff",
  dangerColor: "#ed4c67",
  dangerColorHover: "#d63031",
  playerColor: "#ed4c67",
  playerTextColor: "#ffffff",
  tagColor: "#333333",
  bulletColor: "#ed4c67",
  borderColor: "#f1f2f6",
  stationItemBackgroundColor: "#f5f6fa",
  stationItemShadowColor: "#333333",
  slideshowBackground: "#f5f6fa", // "linear-gradient(#000000 0%, #00000000 100%)",
};

const neonTheme = {
  backgroundColor: "#2c3e50",
  menuBackgroundColor: "#34495e",
  modalColor: "#2c3e50",
  modalFooterColor: "#2c3e50",
  textColor: "#ffffff",
  mainColor: "#82ccdd",
  mainColorHover: "#60a3bc",
  buttonColor: "#000000",
  dangerColor: "#ed4c67",
  dangerColorHover: "#b53471",
  playerColor: "#60a3bc",
  playerTextColor: "#ffffff",
  tagColor: "#333333",
  bulletColor: "#60a3bc",
  borderColor: "#2c3e50",
  stationItemBackgroundColor: "#34495e",
  stationItemShadowColor: "#cccccc",
};

const darkCherryTheme = {
  backgroundColor: "#2c3e50",
  menuBackgroundColor: "#34495e",
  modalColor: "#34495e",
  modalFooterColor: "#34495e",
  textColor: "#ffffff",
  mainColor: "#c44569",
  mainColorHover: "#cf6a87",
  buttonColor: "#ffffff",
  dangerColor: "#c44569",
  dangerColorHover: "#cf6a87",
  playerColor: "#c44569",
  playerTextColor: "#ffffff",
  tagColor: "#cccccc",
  bulletColor: "#cf6a87",
  borderColor: "#2c3e50",
  stationItemBackgroundColor: "#34495e",
  stationItemShadowColor: "#cccccc",
  slideshowBackground: "#222f3e", // "linear-gradient(#000000 0%, #00000000 100%)",
  inputBackgroundColor: "#2c3e50",
  inputBorderColor: "#2c3e50",
  inputTextColor: "#ffffff",
  tagBorderColor: "#2f3640",
  tagBackgroundColor: "#1e272e",
  tagTextColor: "#ffffff",
};

const blackTheme = {
  backgroundColor: "#000000",
  menuBackgroundColor: "#000000",
  modalColor: "#000000",
  modalFooterColor: "#000000",
  textColor: "#ffffff",
  mainColor: "#0984e3",
  mainColorHover: "#076bb8",
  buttonColor: "#ffffff",
  dangerColor: "#ed4c67",
  dangerColorHover: "#b53471",
  playerColor: "#000000",
  playerTextColor: "#ffffff",
  tagColor: "#ffffff",
  bulletColor: "#000000",
  borderColor: "#333333",
  stationItemBackgroundColor: "#34495e",
  stationItemShadowColor: "#cccccc",
};

const darkGreenTheme = {
  backgroundColor: "#2f3640",
  menuBackgroundColor: "#1e272e",
  modalColor: "#1e272e",
  modalFooterColor: "#1e272e",
  textColor: "#ffffff",
  mainColor: "#ffffff",
  mainColorHover: "#f5f6fa",
  buttonColor: "#ffffff",
  dangerColor: "#ed4c67",
  dangerColorHover: "#b53471",
  playerColor: "#1e272e",
  playerTextColor: "#ffffff",
  tagColor: "#f5f6fa",
  bulletColor: "#16a085",
  borderColor: "#1e272e",
  stationItemBackgroundColor: "#1e272e",
  stationItemShadowColor: "#cccccc",
  badgeColor: "#16a085",
  slideshowBackground: "#1e272e", // "linear-gradient(#000000 0%, #00000000 100%)",
  inputBackgroundColor: "#2f3640",
  inputBorderColor: "#777777",
  inputTextColor: "#ffffff",
  collectionBackgroundColor: "#1e272e",
  tagBorderColor: "#2f3640",
  tagBackgroundColor: "#1e272e",
  tagTextColor: "#ffffff",
  primaryButtonColor: "#2f3640",
  primaryButtonHoverColor: "#1e272e",
  primaryButtonBorderColor: "#2f3640",
  primaryButtonTextColor: "#f5f6fa",
};

const mobilePurpleTheme = {
  backgroundColor: "#111111", // #140040 #1c1463
  menuBackgroundColor: "#140040",
  modalColor: "#140040",
  modalFooterColor: "#140040",
  textColor: "#ffffff",
  mainColor: "#f9ca24",
  mainColorHover: "#fff42d",
  buttonColor: "#000000",
  dangerColor: "#c44569",
  dangerColorHover: "#cf6a87",
  playerColor: "#140040",
  playerTextColor: "#ffffff",
  tagColor: "#cccccc",
  bulletColor: "#140040",
  borderColor: "#1c1463",
  stationItemBackgroundColor: "#140040",
  stationItemShadowColor: "#cccccc",
  slideshowBackground: "linear-gradient(180deg, rgb(20, 0, 64), #111111)",
  inputBackgroundColor: "#1c1463",
  inputBorderColor: "#1c1463",
  inputTextColor: "#ffffff",
  tagBorderColor: "#2f3640",
  tagBackgroundColor: "#1e272e",
  tagTextColor: "#ffffff",
  logoColor: "dark"
};
// const prettyPurpleTheme = {
//   backgroundColor: "#2C3A47",
//   menuBackgroundColor: "#182C61",
//   modalColor: "#182C61",
//   modalFooterColor: "#182C61",
//   textColor: "#ffffff",
//   mainColor: "#ffffff",
//   mainColorHover: "#f5f6fa",
//   buttonColor: "#000000",
//   dangerColor: "#ed4c67",
//   dangerColorHover: "#b53471",
//   playerColor: "#182C61",
//   playerTextColor: "#ffffff",
//   tagColor: "#f5f6fa",
//   bulletColor: "#30336b",
//   borderColor: "#2c3e50",
//   stationItemBackgroundColor: "#34495e",
//   stationItemShadowColor: "#cccccc",
//   badgeColor: "#3B3B98"
// };

const themes = {
  default: defaultTheme,
  red: redTheme,
  dark: darkTheme,
  neon: neonTheme,
  black: blackTheme,
  darkCherry: darkCherryTheme,
  darkGreen: darkGreenTheme,
  mobilePurple: mobilePurpleTheme,
};

export default themes;

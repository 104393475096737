import React, { useContext, useMemo, useEffect, useRef } from "react";
import { ToastContainer } from "singulier";

const ToastCtx = React.createContext();

export function ToastContext({ children }) {
  const toastRef = useRef(null);

  const showToast = (msg, type) => {
    if (!msg) {
      msg = 'An unexpected error has occurred.';
    }

    setTimeout(() => {
      switch (type) {
        case "success":
          return toastRef.current.success(msg);
        case "danger":
          return toastRef.current.danger(msg);
        case "warning":
          return toastRef.current.warning(msg);
        case "info":
        default:
          return toastRef.current.info(msg);
      }
    }, 1);
  };

  const ctx = useMemo(() => ({ showToast }), [showToast]);

  return (
    <ToastCtx.Provider value={ctx}>
      <ToastContainer ref={el => {toastRef.current = el}} duration={3000}></ToastContainer>
      {children}
    </ToastCtx.Provider>
  );
}

export function useToastOutlet() {
  const toastCtx = useContext(ToastCtx);
  return toastCtx.showToast;
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Alert } from "singulier";
import {
  CollapsibleCol,
  CollectionButton,
  CollectionContainer,
  CollectionDescription, CollectionGrid,
  CollectionTitle,
  ExpandableCol,
  ScreenContainer,
  Station,
  StationsGrid,
} from "../layout";
import { bGetFilteredStations } from "../../backend/stations";
import { useLocation } from "react-router-dom";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import PageState from "../page-state/PageState";

const SearchResults = ({
  onStationClick,
  onShowStationInfo,
  onViewCollection,
}) => {
  const [stations, setStations] = useState([]);
  const [collections, setCollections] = useState([]);

  const { isLoading, setIsLoading } = useLoadingOutlet();

  const location = useLocation();
  const queryFilter =
    location && location.state && location.state.query
      ? location.state.query.toLowerCase()
      : "";
  const countryFilter =
    location && location.state && location.state.countryFilter
      ? location.state.countryFilter.toLowerCase()
      : "";

  useEffect(() => {
    setIsLoading(true);
    bGetFilteredStations({ queryFilter, countryFilter }).then((response) => {
      setIsLoading(false);
      setStations(response.stations);
      setCollections(response.collections);
    });
  }, [queryFilter, countryFilter]);

  // useEffect(() => {
  //   setStations(allStations.slice(offset, offset + 100));
  // }, [offset]);
  //
  return (
    <ScreenContainer>
      <PerfectScrollbar>
        {!isLoading &&
          (!stations || !stations.length) &&
          (!collections || !collections.length) && (
            <PageState
              image={"noResults"}
              title="No results."
              message={"We could not find any results for your search."}
            />
          )}
        {stations.length === 100 && (
          <Alert factor="info">
            Your search has yielded at least a lot of results, so we returned
            only <strong>the first 100</strong>. Try to refine your search to
            get more specific results.{" "}
          </Alert>
        )}

        {Array.isArray(collections) && collections.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <h1>Collections</h1>
            <CollectionGrid>
              {collections.map((collection, key) => {
                return (
                  <CollectionContainer
                    key={"collection-" + collection.id}
                    colors={JSON.parse(collection.colors || "{}")}
                  >
                    <ExpandableCol
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      <CollectionTitle
                        colors={JSON.parse(collection.colors || "{}")}
                      >
                        {collection.name}
                      </CollectionTitle>
                      {collection.description && (
                        <CollectionDescription
                          colors={JSON.parse(collection.colors || "{}")}
                        >
                          {collection.description}
                        </CollectionDescription>
                      )}
                    </ExpandableCol>
                    <CollapsibleCol
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                    >
                      <CollectionButton
                        colors={JSON.parse(
                          collection.colors ||
                            '{"color1": "#0984e3", "text":"#ffffff"}'
                        )}
                        onClick={() => onViewCollection(collection)}
                      >
                        view collection
                      </CollectionButton>
                    </CollapsibleCol>
                  </CollectionContainer>
                );
              })}
            </CollectionGrid>
          </div>
        )}

        {collections.length > 0 && stations.length > 0 && <h1>Stations</h1>}
        <StationsGrid>
          {stations.map((station) => (
            <Station
              key={"station-" + station.externalId}
              station={station}
              stationClick={onStationClick}
              showStationInfo={onShowStationInfo}
            />
          ))}
        </StationsGrid>
      </PerfectScrollbar>
    </ScreenContainer>
  );
};

export default SearchResults;

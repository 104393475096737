import axios from "axios";
import { API_URL } from "./config";

const AUTH_SERVER_URL = `${API_URL}/api`;

export const bPostIdTokenToSessionLogin = (idToken, sessionToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/auth/sessionLogin`,
        {
          idToken,
          csrfToken: sessionToken,
        },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bCreateUser = (idToken, sessionToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${AUTH_SERVER_URL}/auth/signUp`,
        {
          idToken,
          csrfToken: sessionToken,
        },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

import axios from "axios";
import { API_URL } from "./config";

const SERVER_URL = `${API_URL}/api`;

export const bGetSettings = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/settings/`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bSetSettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${SERVER_URL}/settings/`, data, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

import axios from "axios";
import { API_URL } from "./config";

const METADATA_SERVER_URL = `${API_URL}/api`;

export const bGetFrontPage = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${METADATA_SERVER_URL}/layout/front-page`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

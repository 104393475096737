import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Button } from "singulier";
import {
  PaddedContent,
  ScreenContainer, ScrollbarWrapper,
  Station,
  StationsGrid,
} from "../layout";
import { bGetStations, bGetTags } from "../../backend/stations";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";

const NavigateContainer = styled.div`
  position: fixed;
  top: 80px;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 5px;

  border-radius: 0 0 8px 8px;
  right: 20px;

  button {
    margin: 0 5px;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tag = styled.div`
  padding: 5px 10px;
  border: solid 1px
    ${(props) =>
      props.theme.tagBorderColor
        ? props.theme.tagBorderColor
        : props.theme.tagColor};
  border-radius: 20px;
  color: ${(props) =>
    props.theme.tagTextColor ? props.theme.tagTextColor : props.theme.tagColor};
  background-color: ${(props) =>
    props.theme.tagBackgroundColor
      ? props.theme.tagBackgroundColor
      : "rgba(0, 0, 0, 0)"};
  display: inline-block;
  margin: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StationsList = ({ onStationClick, onShowStationInfo }) => {
  const [stations, setStations] = useState([]);
  const [offset, setOffset] = useState(0);
  const [tags, setTags] = useState([]);

  const { isLoading, setIsLoading } = useLoadingOutlet();

  useEffect(() => {
    bGetTags().then((t) => {
      if (Array.isArray(t)) {
        setTags(t);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    bGetStations(offset, 100).then((response) => {
      setIsLoading(false);
      console.log(response);
      setStations(response);
    });
  }, [offset]);

  return (
    <ScrollbarWrapper>
      <NavigateContainer>
        <Button
          factor="primary"
          onClick={() => setOffset(Math.max(0, offset - 100))}
        >
          previous
        </Button>
        <Button factor="primary" onClick={() => setOffset(offset + 100)}>
          next
        </Button>
      </NavigateContainer>
      <PerfectScrollbar>
        <PaddedContent>
          <TagsContainer>
            {tags.map((tag) => (
              <Link
                to={{
                  pathname: "/search",
                  state: {
                    query: tag,
                  },
                }}
              >
                <Tag>{tag}</Tag>
              </Link>
            ))}
            {tags && tags.length > 0 && (
              <Link to="/tags">
                <Button factor="primary" style={{ margin: 5 }}>
                  explore more tags
                </Button>
              </Link>
            )}
          </TagsContainer>

          <StationsGrid>
            {stations.map((station) => (
              <Station
                key={"station-" + station.externalId}
                station={station}
                stationClick={onStationClick}
                showStationInfo={onShowStationInfo}
              />
            ))}
          </StationsGrid>
        </PaddedContent>
      </PerfectScrollbar>
    </ScrollbarWrapper>
  );
};

export default StationsList;

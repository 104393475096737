import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  PaddedContent,
  ScreenContainer,
  ScrollbarWrapper,
  Station,
  StationsGrid,
} from "../layout";
import { bGetFavorites } from "../../backend/favorites";
import firebase from "firebase";

const Favorites = ({ onStationClick, onShowStationInfo }) => {
  const [stations, setStations] = useState([]);

  useEffect(() => {
    bGetFavorites().then((response) => {
      if (response === "Invalid token.") {
        firebase.auth().signOut();
      } else {
        setStations(response);
      }
    });
  }, []);

  return (
    <ScrollbarWrapper>
      <PerfectScrollbar>
        <PaddedContent>
          <StationsGrid>
            {stations.map((station) => (
              <Station
                key={"station-" + station.externalId}
                station={station}
                stationClick={onStationClick}
                showStationInfo={onShowStationInfo}
              />
            ))}
          </StationsGrid>
        </PaddedContent>
      </PerfectScrollbar>
    </ScrollbarWrapper>
  );
};

export default Favorites;

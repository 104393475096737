import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import "./App.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import Player from "./components/player/Player";
import { getCountries, getStation, getStations } from "./backend/stations";
import StationsList from "./components/stations-list/StationsList";
import { Button, Row, Alert } from "singulier";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Explore from "./components/explore/Explore";
import HeaderRow from "./components/header-row/TitleRow";
import Highlights from "./components/highlights/Highlights";
import StationInfo from "./components/station-info/StationInfo";
import SearchResults from "./components/search-results/SearchResults";
import Settings from "./components/settings/Settings";
import {enableCollectionCreator, firebaseConfig} from "./lib/config";
import LoginModal from "./components/login-modal/LoginModal";
import ViewCollection from "./components/view-collection/ViewCollection";
import Favorites from "./components/favorites/Favorites";
import { useLoadingOutlet } from "./common/loadingContext/loadingContext";
import { CollapsibleCol, ExpandableCol, Loader } from "./components/layout";
import GlobalStyle from "./components/globalStyle";
import themes from "./components/themes";
import MenuCol from "./components/header-row/MenuCol";
import { bGetSettings } from "./backend/settings";
import { useSettingsOutlet } from "./common/settingsContext/settingsContext";
import Profile from "./components/profile/Profile";
import Collections from "./components/collections/Collections";
import TagsList from "./components/tags/Tags";
import { bGetStatus } from "./backend/status";
import { bAddActivity } from "./backend/activity";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { AnimatePresence, motion } from "framer-motion";
import { openWindowWithPost, windowOpenInPost } from "./lib/utils";

let externalWindow;
let externalWindowCheckInterval;

const roundLogo = require("./assets/favicon.png").default;

const AppLogo = styled.img`
  position: absolute;
  left: 24px;
  top: 24px;
  height: 16px;
`;

const ToastMessage = styled.div`
  position: absolute;
  z-index: 101;
  top: 20px;
  right: 20px;
  padding: ${({ hasMessage }) => (hasMessage ? "10px 20px" : "0")};
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 17px;

  opacity: ${({ hasMessage }) => (hasMessage ? "1" : "0")};
  // transition: opacity ease-in-out 0.3s;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
  padding: 5px 10px 5px 10px;
  //background-color: #0984e3;
  background-color: ${(props) =>
    props.theme.badgeColor ? props.theme.badgeColor : props.theme.mainColor};
  border-radius: 0 0 10px 10px;
  z-index: 5;
  box-shadow: 0 0 5px #777777;
  
  img {
    filter: brightness(${(props) =>
        props.theme.logoColor === 'dark' ? 0 : 1});
  }
`;

const topStations = [
  "962dc110-0601-11e8-ae97-52543be04c81", // 80s80s
  "2e26412a-7a88-11ea-8a3b-52543be04c81", //
  "962dc110-0601-11e8-ae97-52543be04c81", //
  "b662e02e-9b1a-41e7-bfd5-54ff1393c351",
  "90927765-7e2b-11ea-8a3b-52543be04c81",
  "645c042d-d8c0-11e9-a861-52543be04c81",
  "a726a172-4cc2-4076-b283-a950218ed0c2",
  "23adf5de-1859-4165-9fe3-6fbd6754ba1f",
  "7036e1ed-0b7d-11e9-a80b-52543be04c81",
];

const LoginOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //background: rgba(0, 0, 0, 0.5);
  //background: linear-gradient(#076bb8, #0984e3);
  background-color: #222f3e;
  z-index: 111;
`;

let messageTimeout;

const LoadingBackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0984e377;
  z-index: -1;
  display: none;
`;

const LoadingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: url(${require("./assets/login/8.jpg").default});
  // background-position: center;
  // background-size: cover;
  z-index: -2;
`;

const LoadingLogoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222f3e;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LoadingLogo = styled.img`
  width: 50%;
  max-width: 128px;
  max-height: 128px;
  border-radius: 50%;
  box-shadow: 0 0 10px #000000;
`;

const StatusContainer = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  color: #ffffff;
  font-size: 12px;
  font-weight: lighter;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .alert {
    width: 100%;
  }
`;

function App() {
  const [showStationInfo, setShowStationInfo] = useState(false);
  const [station, setStation] = useState(null);
  const [collection, setCollection] = useState(null);
  const [collectionIndex, setCollectionIndex] = useState(0);
  const [countries, setCountries] = useState(getCountries());
  const [showedLogin, setShowedLogin] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [theme, setTheme] = useState("red");
  const [status, setStatus] = useState(null);
  const [userIsSignedIn, setUserIsSignedIn] = useState(false);
  const [settingsRetrieved, setSettingsRetrieved] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const { isLoading, setIsLoading } = useLoadingOutlet();
  const { settings, setSettings } = useSettingsOutlet();

  const [message, setMessage] = useState();

  const showToastMessage = (msg) => {
    clearTimeout(messageTimeout);
    setMessage(msg);

    messageTimeout = setTimeout(() => setMessage(""), 3000);
  };

  useEffect(() => {
    bGetStatus().then((s) => {
      if (!s) {
        return;
      }

      setStatus(JSON.parse(s.status));
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (userIsSignedIn && !settingsRetrieved) {
        setIsLoading(true);
        console.log("here - getting settings");
        bGetSettings().then((userSettings) => {
          console.log(userSettings);
          setSettings(userSettings);
          setSettingsRetrieved(true);
          setIsLoading(false);
        });
      }
    }, 1);
  }, [userIsSignedIn, settingsRetrieved]);

  useEffect(() => {
    if (!station || !station.id) {
      return;
    }
    bAddActivity("playHistory", station.id).then(() => {});
  }, [station]);

  useEffect(() => {
    if (collection && collection.stations) {
      console.log(collection.stations[collectionIndex]);
      // setStation(collection.stations[collectionIndex]);
      handleStationClick(collectionIndex, collection);
    }
  }, [collectionIndex]);

  const openPopupPlayer = (s) => {
    console.log("here");
    console.log(s);

    externalWindow = window.open(
      "http://external.radiotal.com/?stationId=" + s.id,
      "window",
      "toolbar=no, menubar=no, resizable=0, width=1000, height=140"
    );

    setTimeout(() => {
      console.log("here");
      try {
        externalWindow.addEventListener("unload", () => {
          setStation(null);
          setIsExternal(null);
        });
      } catch {}
    }, 1000);
  };

  const supportedHttpExtensions = [];

  const handleStationClick = (s, c) => {
    if (c) {
      const hasHttp =
        c.stations.filter((c) => c.streamUrl.substr(0, 5) === "http:").length >
        0;

      setCollection(c);
      setCollectionIndex(s);

      if (hasHttp) {
        setIsExternal(true);
        setStation({ id: c.stations[s].id, external: true });
        openPopupPlayer(c.stations[s]);
      } else {
        setIsExternal(false);
        setStation(c.stations[s]);
      }
    } else {
      setCollection(null);

      const hasHttp = s.streamUrl.substr(0, 5) === "http:";
      if (hasHttp) {
        fetch(s.streamUrl.replace("http://", "https://"))
          .then((r) => {
            console.log("https works");
            setIsExternal(false);
            setStation(s);
          })
          .catch((e) => {
            console.log("https doesn't work");
            setIsExternal(true);
            setStation({ id: s.id, external: true });
            openPopupPlayer(s);
          });
      } else {
        setIsExternal(false);
        setStation(s);
      }
    }
  };

  return (
    <ErrorBoundary>
      {status && (
        <StatusContainer>
          <Alert
            factor={status.type + " dismissable"}
            onClose={() => {
              setStatus(null);
            }}
          >
            {status.message}
          </Alert>
        </StatusContainer>
      )}

      {isLoading && <Loader />}
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            console.log("Is signed in: " + isSignedIn);
            if (isSignedIn) {
              setUserIsSignedIn(true);
            } else {
            }

            return (
              <>
                {isSignedIn && !settingsRetrieved && (
                  <LoadingLogoOverlay>
                    <LoadingLogo src={roundLogo} />
                  </LoadingLogoOverlay>
                )}
                {((isSignedIn && settingsRetrieved) || !isSignedIn) && (
                  <>
                    <ThemeProvider
                      theme={themes[settings.theme] || themes.mobilePurple}
                    >
                      <GlobalStyle />
                      <LogoContainer>
                        <img
                          src={require("./assets/logo-white-small.png").default}
                        />
                      </LogoContainer>

                      <Router>
                        <Row style={{ display: "flex" }}>
                          <CollapsibleCol>
                            {/*<MenuCol showToast={showToastMessage} dir={"column"}/>*/}
                          </CollapsibleCol>
                          <ExpandableCol
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100vh",
                            }}
                          >
                            <Row style={{ flexWrap: "nowrap" }}>
                              <CollapsibleCol>
                                <MenuCol
                                  showToast={showToastMessage}
                                  setSettingsRetrieved={setSettingsRetrieved}
                                  dir={"row"}
                                />
                              </CollapsibleCol>
                              <ExpandableCol>
                                <HeaderRow
                                  showToast={showToastMessage}
                                  setSettingsRetrieved={setSettingsRetrieved}
                                />
                              </ExpandableCol>
                            </Row>
                            <Switch>
                              <Route path="/stations">
                                <StationsList
                                  stations={[]}
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                />
                              </Route>
                              <Route path="/highlights">
                                <Highlights
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                  onViewCollection={setShowCollection}
                                />
                              </Route>
                              <Route path="/discover">
                                <Explore
                                  stations={[]}
                                  countries={countries}
                                  onStationClick={handleStationClick}
                                />
                              </Route>
                              <Route path="/tags">
                                <TagsList />
                              </Route>
                              <Route path="/search">
                                <SearchResults
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                  onViewCollection={setShowCollection}
                                />
                              </Route>
                              <Route path="/settings">
                                <Settings
                                  themeId={theme}
                                  onThemeChange={(tId) => {
                                    setTheme(tId);
                                  }}
                                />
                              </Route>
                              <Route path="/favorites">
                                <Favorites
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                />
                              </Route>
                              <Route path="/collections">
                                <Collections
                                  onViewCollection={setShowCollection}
                                />
                              </Route>
                              <Route path="/profile/:id">
                                <Profile
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                />
                              </Route>
                              <Route path="/">
                                <Highlights
                                  onStationClick={handleStationClick}
                                  onShowStationInfo={setShowStationInfo}
                                  onViewCollection={setShowCollection}
                                />
                              </Route>
                            </Switch>
                          </ExpandableCol>
                        </Row>

                        <AnimatePresence>
                          {(station || isExternal || enableCollectionCreator) && (
                            <motion.div
                              style={{
                                position: "fixed",
                                left: 0,
                                width: "100%",
                                zIndex: 7,
                              }}
                              initial={{ bottom: -400 }}
                              animate={{ bottom: 0 }}
                              exit={{ bottom: -400 }}
                            >
                              <Player
                                isExternal={isExternal}
                                station={station}
                                collection={collection}
                                showToast={showToastMessage}
                                onShowStationInfo={setShowStationInfo}
                                userFavorites={[]}
                                selectNextStation={() =>
                                  setCollectionIndex(
                                    (collectionIndex + 1) %
                                      collection.stations.length
                                  )
                                }
                                selectPreviousStation={() => {
                                  if (collectionIndex <= 0) {
                                    setCollectionIndex(
                                      collection.stations.length - 1
                                    );
                                    return;
                                  }

                                  setCollectionIndex(collectionIndex - 1);
                                }}
                                selectStationByIndex={(idx) => {
                                  setCollectionIndex(idx);
                                }}
                                onViewCollection={() => {
                                  setShowCollection(collection);
                                }}
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>

                        {showStationInfo && (
                          <StationInfo
                            station={showStationInfo}
                            onClose={() => setShowStationInfo(false)}
                          />
                        )}

                        {showCollection && (
                          <ViewCollection
                            collectionId={showCollection}
                            onStationClick={handleStationClick}
                            onShowStationInfo={setShowStationInfo}
                            onClose={() => setShowCollection(false)}
                            selectedStationId={station ? station.id : null}
                          />
                        )}

                        <ToastMessage hasMessage={!!message}>
                          {message}
                        </ToastMessage>
                      </Router>
                    </ThemeProvider>
                  </>
                )}

                {!isSignedIn && !showedLogin && (
                  <ThemeProvider
                    theme={themes[settings.theme] || themes.mobilePurple}
                  >
                    <GlobalStyle />
                    <LoginOverlay>
                      <AppLogo
                        src={require("./assets/logo-white-small.png").default}
                      />
                      <LoadingBackground />
                      <LoadingBackgroundOverlay />

                      <LoginModal
                        inline={true}
                        onClose={() => {
                          setShowedLogin(true);
                        }}
                        setSettingsRetrieved={setSettingsRetrieved}
                      />
                    </LoginOverlay>
                  </ThemeProvider>
                )}
              </>
            );
          }}
        </FirebaseAuthConsumer>
      </FirebaseAuthProvider>
    </ErrorBoundary>
  );
}

export default App;

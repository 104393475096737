import React, { useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Separator,
  Checkbox,
} from "singulier";
import {
  Input,
  ScreenContainer,
  Select,
} from "../layout";
import themes from "../themes";
import { useSettingsOutlet } from "../../common/settingsContext/settingsContext";
import { bSetSettings } from "../../backend/settings";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { useToastOutlet } from "../../common/toastContext/toastContext";

const Subtitle = styled.div`
  font-size: 17px;
  line-height: 17px;
`;

const Info = styled.div`
  font-size: 26px;
  margin-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.borderColor};
`;

const Wrapper = styled.div`
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.menuBackgroundColor};
  margin-top: 40px;
  border-radius: 10px;
`;

const ThemeRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ThemeBullet = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ selected }) =>
    selected ? "solid 2px #333333" : "solid 1px #777777"};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin: 0 5px;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
`

const Settings = ({ onThemeChange }) => {
  const { settings, setSettings } = useSettingsOutlet();
  const { isLoading, setIsLoading } = useLoadingOutlet();
  const showAppToast = useToastOutlet();

  const [initialSettings, setInitialSettings] = useState(settings);

  const updateSetting = (key, value) => {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
  };

  const saveSettings = () => {
    setIsLoading(true);
    bSetSettings(settings).then(() => {
      setInitialSettings({ ...settings, userId: undefined, id: "undefined" });
      showAppToast("Settings saved.", "secondary");
      setIsLoading(false);
    });
  };

  return (
    <ScreenContainer style={{padding: 0}}>
      <PerfectScrollbar>
        <Wrapper>
          <Info>Player settings</Info>
{/*
          <Checkbox
            id="playRandom-checkbox"
            name="playRandom-checkbox"
            label="play a random station automatically when opening the player"
            checked={settings.playRandom}
            onChange={(e) => {
              updateSetting("playRandom", e.target.checked);
            }}
          />

          <Separator size="20px" />
*/}
          <Checkbox
            id="enableShortcuts-checkbox"
            name="enableShortcuts-checkbox"
            label="enable keyboard shortcuts"
            checked={settings.enableShortcuts}
            onChange={(e) => {
              updateSetting("enableShortcuts", e.target.checked);
            }}
          />

          <Separator size="20px" />
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "-5px" }}>
              <Checkbox
                id="screensaver-checkbox"
                name="screensaver-checkbox"
                label="&nbsp;"
                checked={settings.showScreenSaver > 0}
                onChange={(e) => {
                  updateSetting(
                    "showScreenSaver",
                    settings.showScreenSaver > 0 ? 0 : 15
                  );
                }}
              />
            </div>
            automatically show screensaver after
            <Select
              style={{ margin: "0 5px" }}
              value={settings.showScreenSaver}
              onChange={(e) => {
                updateSetting("showScreenSaver", e.target.value);
              }}
            >
              <option value="0" disabled>
                ------
              </option>
              <option value="15">15 seconds</option>
              <option value="30">30 seconds</option>
              <option value="60">1 minute</option>
              <option value="300">5 minutes</option>
              <option value="600">10 minutes</option>
            </Select>
            of inactivity
          </div>
          <Separator size="20px" />
          <ThemeRow>
            <div>Theme: </div>
            {Object.keys(themes).map((tId) => (
              <ThemeBullet
                bgColor={themes[tId].bulletColor}
                key={tId}
                selected={settings.theme === tId}
                onClick={() => {
                  updateSetting("theme", tId);
                }}
              />
            ))}
          </ThemeRow>

          <Separator size="40px" />

          <Info>Profile settings</Info>

          <div>
            Preferred name:
            <Input
              value={settings.preferredName}
              onChange={(e) =>
                updateSetting("preferredName", e.target.value)
              }
              style={{ marginLeft: "10px", minWidth: "200px" }}
              placeholder="optional"
            />
          </div>

{/*
          TODO disabled for now
          <Separator size="20px" />
          <Checkbox
            id="hideFavorites-checkbox"
            name="hideFavorites-checkbox"
            label="hide favourite stations"
            checked={settings.hideFavorites}
            onChange={(e) => {
              updateSetting("hideFavorites", e.target.checked);
            }}
          />
*/}

          <Separator size="20px" />
          <Checkbox
            id="privateProfile-checkbox"
            name="privateProfile-checkbox"
            label="make profile private"
            checked={settings.privateProfile}
            onChange={(e) => {
              updateSetting("privateProfile", e.target.checked);
            }}
          />
          <Separator size="40px" />

          <Button
            factor="primary"
            style={{ marginRight: "10px" }}
            onClick={saveSettings}
          >
            Save settings
          </Button>
          <Button
            factor="secondary"
            onClick={() => setSettings(initialSettings)}
          >
            Cancel
          </Button>
        </Wrapper>
      </PerfectScrollbar>
    </ScreenContainer>
  );
};

export default Settings;

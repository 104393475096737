import axios from "axios";
import { API_URL } from "./config";

const countries = require("./countries.json");

const SERVER_URL = `${API_URL}/api`;

export const bGetStations = (start, count) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/stations/?start=${start}&length=${count}`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGetFilteredStations = (filters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${SERVER_URL}/stations/search`,
        { filters: filters },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bGetCollection = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/stations/collection/` + id, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const getCountries = () => {
  // const items = stations
  //   .map((s) => (JSON.stringify({ name: s.country, code: s.countrycode })))
  //   .filter((item, key, list) => list.indexOf(item) === key)
  //   .map((s) => JSON.parse(s))
  //   .filter((i) => i.name.trim() !== "")
  //   .sort((a, b) => a.name.localeCompare(b.name));
  //
  // console.log(JSON.stringify(items));
  return countries;
};

export const bGetTags = (count) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/stations/tags/?count=` + count, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const getPicksOfTheWeek = () => {
  return [
    {
      changeuuid: "b579a74a-1e40-11e8-a334-52543be04c81",
      stationuuid: "962dc110-0601-11e8-ae97-52543be04c81",
      name: "80s80s",
      url: "http://streams.80s80s.de/web/mp3-192/streams.80s80s.de/",
      url_resolved:
        "http://80s80s.hoerradar.de/80s80s-mweb-mp3-hq?sABC=60n4550p%230%236p0pqp0n25q8qssq8oqso890on0n3rn5%23fgernzf.80f80f.qr&=&amsparams=playerid:streams.80s80s.de;skey:1621382412",
      homepage: "http://80s80s.de/",
      favicon:
        "http://media.streamabc.com/80s80s.de/web/600x600_1_80s80s_colored.jpg",
      tags: "80s",
      country: "Germany",
      countrycode: "DE",
      state: "Schleswig-Holstein",
      language: "german",
      languagecodes: "",
      votes: 909,
      lastchangetime: "2021-02-07 13:11:47",
      lastchangetime_iso8601: "2021-02-07T13:11:47Z",
      codec: "MP3",
      bitrate: 192,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 00:00:22",
      lastchecktime_iso8601: "2021-05-19T00:00:22Z",
      lastcheckoktime: "2021-05-19 00:00:22",
      lastcheckoktime_iso8601: "2021-05-19T00:00:22Z",
      lastlocalchecktime: "2021-05-19 00:00:22",
      lastlocalchecktime_iso8601: "2021-05-19T00:00:22Z",
      clicktimestamp: "2021-05-19 07:14:37",
      clicktimestamp_iso8601: "2021-05-19T07:14:37Z",
      clickcount: 270,
      clicktrend: -5,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "691b8313-266b-408d-8624-bd6fddadac79",
      stationuuid: "b662e02e-9b1a-41e7-bfd5-54ff1393c351",
      name: "95.7 BIG FM",
      url: "https://stream.revma.ihrhls.com/zc2689",
      url_resolved:
        "https://n39b-e2.revma.ihrhls.com/zc2689?rj-ttl=5&rj-tok=AAABeYF62nwAOlBpPabJ6hLryQ",
      homepage: "https://957bigfm.iheart.com/",
      favicon: "https://radiostationusa.fm/assets/image/radio/180/WRIT-FM.png",
      tags: "oldies",
      country: "The United States Of America",
      countrycode: "US",
      state: "Milwaukee WI",
      language: "english",
      languagecodes: "",
      votes: 5,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC",
      bitrate: 0,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-18 21:46:29",
      lastchecktime_iso8601: "2021-05-18T21:46:29Z",
      lastcheckoktime: "2021-05-18 21:46:29",
      lastcheckoktime_iso8601: "2021-05-18T21:46:29Z",
      lastlocalchecktime: "2021-05-18 21:46:29",
      lastlocalchecktime_iso8601: "2021-05-18T21:46:29Z",
      clicktimestamp: "2021-05-17 12:22:35",
      clicktimestamp_iso8601: "2021-05-17T12:22:35Z",
      clickcount: 1,
      clicktrend: -1,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "90927775-7e2b-11ea-8a3b-52543be04c81",
      stationuuid: "90927765-7e2b-11ea-8a3b-52543be04c81",
      name: "BAG",
      url: "http://radio-holding.ru:9000/rfm_aac_64",
      url_resolved: "http://radio-holding.ru:9000/rfm_aac_64",
      homepage: "https://vk.com/radiofreemusic",
      favicon:
        "https://sun1-86.userapi.com/aMF4kGfji2yC7loxd945dktykoqcqF5uaQWC9A/06_gDThX2es.jpg?ava=1",
      tags:
        "radio fresh style - fresh,fashionable,popular!,радио в стиле фреш - свежее,модное,популярное!",
      country: "The Russian Federation",
      countrycode: "RU",
      state: "",
      language: "english,russian",
      languagecodes: "",
      votes: 7,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC",
      bitrate: 64,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 04:44:02",
      lastchecktime_iso8601: "2021-05-19T04:44:02Z",
      lastcheckoktime: "2021-05-19 04:44:02",
      lastcheckoktime_iso8601: "2021-05-19T04:44:02Z",
      lastlocalchecktime: "2021-05-19 04:44:02",
      lastlocalchecktime_iso8601: "2021-05-19T04:44:02Z",
      clicktimestamp: "2021-05-18 19:40:38",
      clicktimestamp_iso8601: "2021-05-18T19:40:38Z",
      clickcount: 2,
      clicktrend: 1,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "645c043f-d8c0-11e9-a861-52543be04c81",
      stationuuid: "645c042d-d8c0-11e9-a861-52543be04c81",
      name: "Bahia FM 88.7",
      url: "http://r13.ciclano.io:8903/stream",
      url_resolved: "http://r13.ciclano.io:8903/stream",
      homepage: "http://www.radiobahiafm.com.br/",
      favicon:
        "https://mytuner.global.ssl.fastly.net/media/tvos_radios/3LCDX85kQZ.png",
      tags: "",
      country: "Brazil",
      countrycode: "BR",
      state: "",
      language: "portuguese",
      languagecodes: "",
      votes: 23,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC+",
      bitrate: 96,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 00:52:47",
      lastchecktime_iso8601: "2021-05-19T00:52:47Z",
      lastcheckoktime: "2021-05-19 00:52:47",
      lastcheckoktime_iso8601: "2021-05-19T00:52:47Z",
      lastlocalchecktime: "2021-05-19 00:52:47",
      lastlocalchecktime_iso8601: "2021-05-19T00:52:47Z",
      clicktimestamp: "2021-05-18 23:08:56",
      clicktimestamp_iso8601: "2021-05-18T23:08:56Z",
      clickcount: 5,
      clicktrend: 2,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "dfe6f2f0-d75c-480c-8bae-535b9926cb79",
      stationuuid: "a726a172-4cc2-4076-b283-a950218ed0c2",
      name: "BBC Radio 1",
      url: "https://stream.live.vc.bbcmedia.co.uk/bbc_radio_one",
      url_resolved: "https://stream.live.vc.bbcmedia.co.uk/bbc_radio_one",
      homepage: "https://www.bbc.co.uk/radio1",
      favicon:
        "https://rmp.files.bbci.co.uk/rmp-shared-assets/1.4.0/img/network-logos/national/bbc_radio_one_colour.svg",
      tags: "bbc,adult contemporary",
      country: "The United Kingdom Of Great Britain And Northern Ireland",
      countrycode: "GB",
      state: "",
      language: "english",
      languagecodes: "",
      votes: 22,
      lastchangetime: "2021-03-23 15:52:12",
      lastchangetime_iso8601: "2021-03-23T15:52:12Z",
      codec: "MP3",
      bitrate: 128,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 04:35:13",
      lastchecktime_iso8601: "2021-05-19T04:35:13Z",
      lastcheckoktime: "2021-05-19 04:35:13",
      lastcheckoktime_iso8601: "2021-05-19T04:35:13Z",
      lastlocalchecktime: "2021-05-18 20:56:59",
      lastlocalchecktime_iso8601: "2021-05-18T20:56:59Z",
      clicktimestamp: "2021-05-19 04:17:22",
      clicktimestamp_iso8601: "2021-05-19T04:17:22Z",
      clickcount: 29,
      clicktrend: -5,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "1aed9220-152d-48c6-b1c2-6ddc004c61a7",
      stationuuid: "23adf5de-1859-4165-9fe3-6fbd6754ba1f",
      name: "Beach 105.5",
      url:
        "https://playerservices.streamtheworld.com/api/livestream-redirect/WBHUFMAAC.aac",
      url_resolved: "https://17533.live.streamtheworld.com/WBHUFMAAC.aac",
      homepage: "https://www.beach1055.com/",
      favicon: "https://radiostationusa.fm/assets/image/radio/180/WBHUFM.jpg",
      tags: "adult hits",
      country: "The United States Of America",
      countrycode: "US",
      state: "Saint Augustine Beach FL",
      language: "english",
      languagecodes: "",
      votes: 1,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC+",
      bitrate: 32,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 06:29:47",
      lastchecktime_iso8601: "2021-05-19T06:29:47Z",
      lastcheckoktime: "2021-05-19 06:29:47",
      lastcheckoktime_iso8601: "2021-05-19T06:29:47Z",
      lastlocalchecktime: "2021-05-19 06:29:47",
      lastlocalchecktime_iso8601: "2021-05-19T06:29:47Z",
      clicktimestamp: "2021-05-16 14:22:32",
      clicktimestamp_iso8601: "2021-05-16T14:22:32Z",
      clickcount: 1,
      clicktrend: 0,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "7036e201-0b7d-11e9-a80b-52543be04c81",
      stationuuid: "7036e1ed-0b7d-11e9-a80b-52543be04c81",
      name: "Capital 958 Radio",
      url:
        "http://playerservices.streamtheworld.com/api/livestream-redirect/CAPITAL958FMAAC.aac",
      url_resolved: "http://14033.live.streamtheworld.com/CAPITAL958FMAAC.aac",
      homepage: "http://www.meradio.sg/radio/capital-958",
      favicon:
        "http://www.meradio.sg/api/blob/10711292/c1d17b4ea5804a7465b72e2662852a6d/capital-958-logoImage.png",
      tags: "",
      country: "Singapore",
      countrycode: "SG",
      state: "Singapore",
      language: "chinese",
      languagecodes: "",
      votes: 102,
      lastchangetime: "2021-02-07 13:11:47",
      lastchangetime_iso8601: "2021-02-07T13:11:47Z",
      codec: "AAC+",
      bitrate: 48,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 03:53:40",
      lastchecktime_iso8601: "2021-05-19T03:53:40Z",
      lastcheckoktime: "2021-05-19 03:53:40",
      lastcheckoktime_iso8601: "2021-05-19T03:53:40Z",
      lastlocalchecktime: "2021-05-18 23:03:37",
      lastlocalchecktime_iso8601: "2021-05-18T23:03:37Z",
      clicktimestamp: "2021-05-19 07:01:02",
      clicktimestamp_iso8601: "2021-05-19T07:01:02Z",
      clickcount: 20,
      clicktrend: 4,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "2e264137-7a88-11ea-8a3b-52543be04c81",
      stationuuid: "2e26412a-7a88-11ea-8a3b-52543be04c81",
      name: "CBC Radio 1 (Calgary, AB)",
      url:
        "https://cbcliveradio-lh.akamaihd.net/i/CBCR1_CGY@372026/master.m3u8",
      url_resolved:
        "https://cbcliveradio-lh.akamaihd.net/i/CBCR1_CGY@372026/master.m3u8",
      homepage: "https://www.radio.net/s/cbccalgary",
      favicon:
        "https://static.radio.net/images/broadcasts/6e/c6/34607/c300.png",
      tags: "",
      country: "",
      countrycode: "",
      state: "",
      language: "en",
      languagecodes: "",
      votes: 16,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC",
      bitrate: 46,
      hls: 1,
      lastcheckok: 1,
      lastchecktime: "2021-05-18 22:37:23",
      lastchecktime_iso8601: "2021-05-18T22:37:23Z",
      lastcheckoktime: "2021-05-18 22:37:23",
      lastcheckoktime_iso8601: "2021-05-18T22:37:23Z",
      lastlocalchecktime: "2021-05-18 22:37:23",
      lastlocalchecktime_iso8601: "2021-05-18T22:37:23Z",
      clicktimestamp: "2021-05-18 23:59:07",
      clicktimestamp_iso8601: "2021-05-18T23:59:07Z",
      clickcount: 4,
      clicktrend: 2,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
  ];
};

export const getCountryOfTheWeek = () => {
  return [
    {
      changeuuid: "c9fa1ff7-c305-11e8-aaf2-52543be04c81",
      stationuuid: "c9fa1fea-c305-11e8-aaf2-52543be04c81",
      name: "96Five",
      url: "http://stream2.cnmns.net/96five",
      url_resolved: "http://stream2.cnmns.net/96five",
      homepage: "https://www.96five.com/",
      favicon:
        "https://www.96five.com/wp-content/uploads/2017/01/cropped-96five_logo_icon512-270x270.png",
      tags: "family,christian",
      country: "Australia",
      countrycode: "AU",
      state: "Queensland",
      language: "english",
      languagecodes: "",
      votes: 13,
      lastchangetime: "2021-02-07 13:11:47",
      lastchangetime_iso8601: "2021-02-07T13:11:47Z",
      codec: "AAC+",
      bitrate: 96,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 02:41:08",
      lastchecktime_iso8601: "2021-05-19T02:41:08Z",
      lastcheckoktime: "2021-05-19 02:41:08",
      lastcheckoktime_iso8601: "2021-05-19T02:41:08Z",
      lastlocalchecktime: "2021-05-19 00:01:28",
      lastlocalchecktime_iso8601: "2021-05-19T00:01:28Z",
      clicktimestamp: "2021-05-18 21:53:48",
      clicktimestamp_iso8601: "2021-05-18T21:53:48Z",
      clickcount: 2,
      clicktrend: 0,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "961a0454-0601-11e8-ae97-52543be04c81",
      stationuuid: "961a0451-0601-11e8-ae97-52543be04c81",
      name: "ABC Local Radio 702 Sydney (AAC)",
      url: "http://live-radio01.mediahubaustralia.com/2LRW/aac/",
      url_resolved: "http://live-radio01.mediahubaustralia.com/2LRW/aac/",
      homepage: "http://www.abc.net.au/sydney/",
      favicon: "http://www.abc.net.au/cm/rimage/7893524-1x1-large.png?v=6",
      tags: "public radio,sydney,abc,local news,information,news",
      country: "Australia",
      countrycode: "AU",
      state: "New South Wales",
      language: "english",
      languagecodes: "",
      votes: 66,
      lastchangetime: "2021-02-07 13:11:46",
      lastchangetime_iso8601: "2021-02-07T13:11:46Z",
      codec: "AAC+",
      bitrate: 48,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 03:58:58",
      lastchecktime_iso8601: "2021-05-19T03:58:58Z",
      lastcheckoktime: "2021-05-19 03:58:58",
      lastcheckoktime_iso8601: "2021-05-19T03:58:58Z",
      lastlocalchecktime: "2021-05-19 03:58:58",
      lastlocalchecktime_iso8601: "2021-05-19T03:58:58Z",
      clicktimestamp: "2021-05-18 21:34:02",
      clicktimestamp_iso8601: "2021-05-18T21:34:02Z",
      clickcount: 4,
      clicktrend: 0,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "7ef575a2-fc85-11e9-bbf2-52543be04c81",
      stationuuid: "7ef57573-fc85-11e9-bbf2-52543be04c81",
      name: "hit103.1 Townsville",
      url: "https://wz4web.scahw.com.au/live/4ram_32.stream/playlist.m3u8",
      url_resolved:
        "https://wz4web.scahw.com.au/live/4ram_32.stream/playlist.m3u8",
      homepage: "https://www.hit.com.au/townsville",
      favicon:
        "https://myradio-img-prod.scalabs.com.au/api/assets/281aad10-a0b3-43e9-9b29-54a115b734c4?version=0",
      tags: "queensland,townsville,australia",
      country: "Australia",
      countrycode: "AU",
      state: "Queensland",
      language: "english",
      languagecodes: "",
      votes: 4,
      lastchangetime: "2021-02-07 13:11:48",
      lastchangetime_iso8601: "2021-02-07T13:11:48Z",
      codec: "AAC+",
      bitrate: 33,
      hls: 1,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 07:31:03",
      lastchecktime_iso8601: "2021-05-19T07:31:03Z",
      lastcheckoktime: "2021-05-19 07:31:03",
      lastcheckoktime_iso8601: "2021-05-19T07:31:03Z",
      lastlocalchecktime: "2021-05-19 04:12:59",
      lastlocalchecktime_iso8601: "2021-05-19T04:12:59Z",
      clicktimestamp: "2021-05-16 22:14:16",
      clicktimestamp_iso8601: "2021-05-16T22:14:16Z",
      clickcount: 1,
      clicktrend: 0,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "cd4e4024-1c7a-11e8-a334-52543be04c81",
      stationuuid: "960d1395-0601-11e8-ae97-52543be04c81",
      name: "Southern FM",
      url: "http://stream.swifthosting.com.au:8080/stream",
      url_resolved: "http://stream.swifthosting.com.au:8080/stream",
      homepage: "http://www.southernfm.com.au/",
      favicon:
        "https://www.southernfm.com.au/wp-content/uploads/logos/88.3_Logo.jpg",
      tags: "community radio",
      country: "Australia",
      countrycode: "AU",
      state: "Victoria",
      language: "english",
      languagecodes: "",
      votes: 7,
      lastchangetime: "2021-02-07 13:11:47",
      lastchangetime_iso8601: "2021-02-07T13:11:47Z",
      codec: "MP3",
      bitrate: 128,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-19 02:15:15",
      lastchecktime_iso8601: "2021-05-19T02:15:15Z",
      lastcheckoktime: "2021-05-19 02:15:15",
      lastcheckoktime_iso8601: "2021-05-19T02:15:15Z",
      lastlocalchecktime: "2021-05-18 22:27:35",
      lastlocalchecktime_iso8601: "2021-05-18T22:27:35Z",
      clicktimestamp: "2021-05-18 18:13:58",
      clicktimestamp_iso8601: "2021-05-18T18:13:58Z",
      clickcount: 2,
      clicktrend: 2,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
    {
      changeuuid: "7b8ea587-b893-4884-a8cd-e7c0c14589c2",
      stationuuid: "59491a3b-ac10-4737-956c-cd4ca5f3fb4c",
      name: "WaveFM 96.5",
      url: "http://23023.live.streamtheworld.com/WOL_WAVE_FMAAC.aac",
      url_resolved: "http://23023.live.streamtheworld.com/WOL_WAVE_FMAAC.aac",
      homepage: "https://www.wavefm.com.au/",
      favicon: "https://www.wavefm.com.au/images/logos/new-logo.svg",
      tags: "",
      country: "Australia",
      countrycode: "AU",
      state: "",
      language: "",
      languagecodes: "",
      votes: 4,
      lastchangetime: "2021-04-02 10:20:17",
      lastchangetime_iso8601: "2021-04-02T10:20:17Z",
      codec: "AAC+",
      bitrate: 64,
      hls: 0,
      lastcheckok: 1,
      lastchecktime: "2021-05-18 19:07:39",
      lastchecktime_iso8601: "2021-05-18T19:07:39Z",
      lastcheckoktime: "2021-05-18 19:07:39",
      lastcheckoktime_iso8601: "2021-05-18T19:07:39Z",
      lastlocalchecktime: "2021-05-18 19:07:39",
      lastlocalchecktime_iso8601: "2021-05-18T19:07:39Z",
      clicktimestamp: "2021-05-19 05:19:47",
      clicktimestamp_iso8601: "2021-05-19T05:19:47Z",
      clickcount: 2,
      clicktrend: 1,
      ssl_error: 0,
      geo_lat: null,
      geo_long: null,
    },
  ];
};

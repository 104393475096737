import React from "react";

import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Row,
} from "singulier";
import styled from "styled-components";
import {
  CollapsibleCol,
  ExpandableCol,
  External,
  ImageWithFallback,
} from "../layout";

const Subtitle = styled.div`
  font-size: 17px;
  line-height: 17px;
`;

const Info = styled.div`
  font-size: 26px;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #0984e3;

  &:hover {
    color: #076bb8;
  }
`;

const Tag = styled.div`
  display: inline-block;
  background-color: #0984e3;
  color: #ffffff;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  font-size: 17px;

  &:hover {
    background-color: #076bb8;
    cursor: pointer;
  }
`;

const getTags = (tagsArray) => {
  if (!Array.isArray(tagsArray)) {
    return tagsArray.split(",").map((tag) => <Tag key={tag} factor="primary">{tag}</Tag>);
  }

  return (
    <>
      {tagsArray.map((tag) => (
        <Tag key={tag} factor="primary">{tag}</Tag>
      ))}
    </>
  );
};

const StationInfo = ({ station, onClose }) => {
  return (
    <Modal factor="large" onClose={onClose} style={{zIndex: "1031"}}>
      <ModalHeader>{station.name}</ModalHeader>
      <ModalContent>
        <Row>
          <ExpandableCol>
            {station.country && (
              <>
                <Subtitle>Country</Subtitle>
                <Info>{station.country}</Info>
              </>
            )}

            {station.state && (
              <>
                <Subtitle>State</Subtitle>
                <Info>{station.state}</Info>
              </>
            )}

            {station.language && (
              <>
                <Subtitle>Language</Subtitle>
                <Info>{station.language}</Info>
              </>
            )}

            {station.tags && (
              <>
                <Subtitle>Tags</Subtitle>
                <Info>{getTags(station.tags)}</Info>
              </>
            )}

            {station.homepage && (
              <>
                <Subtitle>Website</Subtitle>
                <Link href={station.homepage} target="_blank">
                  <Info>
                    {station.homepage} <External style={{ height: "20px" }} />
                  </Info>
                </Link>
              </>)}
          </ExpandableCol>
          <CollapsibleCol>
            <ImageWithFallback
              src={station.image}
              fallbackSrc={require("../../assets/radio.png").default}
              style={{
                width: "240px",
                height: "240px",
                borderRadius: "10px",
                marginLeft: "20px",
              }}
            />
          </CollapsibleCol>
        </Row>
      </ModalContent>
      <ModalFooter>
        <Button factor="secondary" onClick={onClose}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StationInfo;

import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Modal } from "singulier";
import styled from "styled-components";
import { Station, StationsGrid } from "../layout";
import { bGetCollection } from "../../backend/stations";
import "./ViewCollection.scss";

const Content = styled.div`
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    180deg,
    ${({ colors }) => colors.color1} 0,
    ${({ colors }) => colors.color2} 100%,
    #ffffff00 120px
  );
  min-height: 40vh;
  position: relative;
  display: flex;
  flex-direction: column;
  //background: linear-gradient(#076bb8, #0984e3);
`;

const ContentBg = styled.div`
  display: none;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const Footer = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CollectionTitle = styled.div`
  font-size: 54px;
  font-weight: bold;
  color: ${({ colors }) => colors.title};
  text-align: center;
`;

const CollectionDescription = styled.div`
  font-size: 14px;
  text-align: center;
  // color: ${({ colors }) => colors.text};
  //
  // a {
  //   color: ${({ colors }) => colors.text};
  //   font-weight: bold;
  // }

  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 10px;
  color: #ffffff;

  a {
    color: #ffffff;
    font-weight: bold;
  }
`;

const CollectionButton = styled.button`
  background: ${({ colors }) => colors.color1};
  border: solid 1px ${({ colors }) => colors.color1};
  color: ${({ colors }) => colors.title};
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  cursor: pointer;
  outline: 0;

  &:hover {
    filter: brightness(0.8);
  }
`;

const GridWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ScrollbarWrapper = styled.div`
  width: 100%;
  max-height: 90vh;

  .scrollbar-container {
    max-height: 90vh;
  }
`;

const ViewCollection = ({
  collectionId,
  onClose,
  onStationClick,
  onShowStationInfo,
  selectedStationId,
}) => {
  const [collection, setCollection] = useState();
  const [descriptionExtended, setDescriptionExtended] = useState(false);

  useEffect(() => {
    if (collectionId.id) {
      setCollection(collectionId);
      return;
    }
    bGetCollection(collectionId).then((response) => {
      setCollection(response);
    });
  }, []);

  return (
    <Modal factor="large" onClose={onClose} className="collection-modal">
      {collection && (
        <ScrollbarWrapper>
          <PerfectScrollbar>
            <Content colors={JSON.parse(collection.colors || "{}")}>
              {/*<ContentBg backgroundImg={collection.background} />*/}
              <CollectionTitle colors={JSON.parse(collection.colors || "{}")}>
                {collection.name}
              </CollectionTitle>
              {collection.description && (
                <CollectionDescription
                  colors={JSON.parse(collection.colors || "{}")}
                >
                  {descriptionExtended && (
                    <div>
                      {collection.description}{" "}
                      {collection.description.length > 100 && (
                        <a
                          href="#"
                          onClick={() => setDescriptionExtended(false)}
                        >
                          see less
                        </a>
                      )}
                    </div>
                  )}
                  {!descriptionExtended && (
                    <div>
                      {collection.description.substr(0, 100)}
                      {collection.description.length > 100 && (
                        <span>
                          ...{" "}
                          <a
                            href="#"
                            onClick={() => setDescriptionExtended(true)}
                          >
                            see more
                          </a>
                        </span>
                      )}
                    </div>
                  )}
                </CollectionDescription>
              )}
              <GridWrapper>
                <StationsGrid style={{ justifyContent: "space-around" }}>
                  {collection.stations.map((station, idx) => (
                    <Station
                      key={"station-" + station.externalId}
                      station={station}
                      stationClick={() => onStationClick(idx, collection)}
                      showStationInfo={onShowStationInfo}
                      selected={station.id === selectedStationId}
                    />
                  ))}
                </StationsGrid>
              </GridWrapper>

              <Footer>
                <CollectionButton
                  onClick={onClose}
                  colors={JSON.parse(collection.colors || "{}")}
                >
                  close
                </CollectionButton>
              </Footer>
            </Content>
          </PerfectScrollbar>
        </ScrollbarWrapper>
      )}
    </Modal>
  );
};

export default ViewCollection;

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Highlights.scss";

import { Row, Button } from "singulier";
import {
  centeredStyle,
  CollapsibleCol,
  ExpandableCol,
  PaddedContent, ScrollbarWrapper,
  Station,
  StationsGrid,
} from "../layout";
import { bGetFrontPage } from "../../backend/layout";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";

const SectionTitle = styled.div`
  font-size: 36px;
  font-weight: 100;
  color: ${(props) => props.theme.textColor};
`;

const CarouselWrapper = styled.div`
  padding: 40px 0;
  background: ${(props) =>
    props.theme.slideshowBackground ? props.theme.slideshowBackground : ""};
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FeaturedStations = styled.div`
  min-height: 40vh;
  width: 70vw;
  max-width: 1200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${(props) => {
    const color = props.theme.badgeColor ? props.theme.badgeColor : props.theme.mainColor;
    return `linear-gradient(45deg, ${color}77 0%, ${color}CC 100%)`;
  }};
`;

const Highlights = ({
  onStationClick,
  onShowStationInfo,
  onViewCollection,
}) => {
  const { isLoading, setIsLoading } = useLoadingOutlet();

  const [sections, setSections] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    bGetFrontPage().then((response) => {
      console.log(response);
      setIsLoading(false);
      setSections(response);
    });
  }, []);

  const featuredStationsCollection = useMemo(
    () =>
      sections && sections.length
        ? sections.find((s) => s.type === "featuredStations")
        : null,
    [sections]
  );

  return (
    <ScrollbarWrapper>
      <PerfectScrollbar>
        {/*<CarouselWrapper>*/}
        {/*  <div*/}
        {/*    style={{ ...centeredStyle, maxWidth: "1000px", margin: "0 auto" }}*/}
        {/*  >*/}
        {/*    <Carousel*/}
        {/*      showArrows={true}*/}
        {/*      dynamicHeight={true}*/}
        {/*      showThumbs={false}*/}
        {/*      showIndicators={false}*/}
        {/*      showStatus={false}*/}
        {/*      swipeable={true}*/}
        {/*      infiniteLoop={true}*/}
        {/*    >*/}
        {/*      */}
        {/*      <div className="carousel-slide">*/}
        {/*        <img src={require("../../assets/carousel/1.jpg").default} />*/}
        {/*        <div className="carousel-info">*/}
        {/*          <div className="carousel-info-title">Featured Stations</div>*/}
        {/*          <div>*/}
        {/*            <Button*/}
        {/*              factor="secondary large"*/}
        {/*              onClick={() => onViewCollection("featuredStations")}*/}
        {/*            >*/}
        {/*              view collection*/}
        {/*            </Button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*      {sections &&*/}
        {/*        sections.length &&*/}
        {/*        sections.filter(s => s.layout === 'stations-group').slice(0, 3).map((section) => (*/}
        {/*          <div className="carousel-slide" style={{*/}
        {/*            background: section.color1*/}
        {/*          }}>*/}
        {/*            <img src={require("../../assets/carousel/1.jpg").default} />*/}
        {/*            <div className="carousel-info">*/}
        {/*              <div className="carousel-info-title">*/}
        {/*                {section.title}*/}
        {/*              </div>*/}
        {/*              <div>*/}
        {/*                <Button*/}
        {/*                  factor="secondary large"*/}
        {/*                  onClick={() => onViewCollection("bringBackThe80s")}*/}
        {/*                >*/}
        {/*                  view collection*/}
        {/*                </Button>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        ))}*/}

        {/*      */}
        {/*      <div className="carousel-slide">*/}
        {/*        <img src={require("../../assets/carousel/1.jpg").default} />*/}
        {/*        <div className="carousel-info">*/}
        {/*          <div className="carousel-info-title">Bring back the 80s!</div>*/}
        {/*          <div>*/}
        {/*            <Button*/}
        {/*              factor="secondary large"*/}
        {/*              onClick={() => onViewCollection("bringBackThe80s")}*/}
        {/*            >*/}
        {/*              view collection*/}
        {/*            </Button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <img src={require("../../assets/carousel/2.jpg").default} />*/}
        {/*        <div className="carousel-info">*/}
        {/*          <div className="carousel-info-title">Get your lo-fi on</div>*/}
        {/*          <div>*/}
        {/*            <Button*/}
        {/*              factor="danger large"*/}
        {/*              onClick={() => onViewCollection("getYourLoFiOn")}*/}
        {/*            >*/}
        {/*              view collection*/}
        {/*            </Button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <img src={require("../../assets/carousel/3.jpg").default} />*/}
        {/*        <div className="carousel-info">*/}
        {/*          <div className="carousel-info-title">Relaxing tunes</div>*/}
        {/*          <div>*/}
        {/*            <Button factor="secondary large">view collection</Button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*    </Carousel>*/}
        {/*  </div>*/}
        {/*</CarouselWrapper>*/}

        {featuredStationsCollection && (
          <CarouselWrapper>
            <FeaturedStations>
              <div className="carousel-info-title">Featured Stations</div>
              <div className="carousel-info-description">A human-curated collection of beautiful stations.</div>
              <div>
                <Button
                  factor="secondary large"
                  onClick={() => onViewCollection("featuredStations")}
                >
                  view collection
                </Button>
              </div>
              {/*
              <Row>
                {featuredStationsCollection.list.map((station, idx) => (
                  <Station
                    key={"station-" + station.externalId}
                    station={station}
                    hideName
                    stationClick={() =>
                      onStationClick(idx, {
                        stations: featuredStationsCollection.list,
                      })
                    }
                    showStationInfo={onShowStationInfo}
                  />
                ))}
              </Row>
*/}
            </FeaturedStations>
          </CarouselWrapper>
        )}

        <PaddedContent>
          {Array.isArray(sections) && (
            <div style={{ marginTop: "20px" }}>
              {sections
                .filter(
                  (s) =>
                    s.layout === "stations-group" &&
                    s.type !== "featuredStations"
                )
                .map((section, key) => {
                  return (
                    <div
                      key={"section-" + key}
                      style={{ marginTop: "40px" }}
                      className="section-container"
                    >
                      <Row>
                        <ExpandableCol>
                          <SectionTitle>{section.title}</SectionTitle>
                        </ExpandableCol>
                        <CollapsibleCol>
                          <Button
                            factor="primary"
                            onClick={() => {
                              onStationClick(key, { stations: section.list });
                            }}
                          >
                            play all
                          </Button>
                        </CollapsibleCol>
                      </Row>
                      <StationsGrid>
                        {section.list.map((station) => (
                          <Station
                            key={"station-" + station.externalId}
                            station={station}
                            stationClick={onStationClick}
                            showStationInfo={onShowStationInfo}
                          />
                        ))}
                      </StationsGrid>
                    </div>
                  );
                })}
            </div>
          )}
        </PaddedContent>
      </PerfectScrollbar>
    </ScrollbarWrapper>
  );
};

export default Highlights;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Row,
  Initials,
  DropdownToggle,
  DropdownList,
  NavContainer,
  NavSeparator,
  NavItem,
  Avatar,
} from "singulier";
import {
  ExpandableCol,
  Input,
} from "../layout";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import firebase from "firebase/app";
import "firebase/auth";
import LoginModal from "../login-modal/LoginModal";
import { useSettingsOutlet } from "../../common/settingsContext/settingsContext";

const FavoritesButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #f5f6fa;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #f5f6fa;

  &:hover {
    background-color: #d4d8ea;
  }

  img {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

const SearchCol = styled(ExpandableCol)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex: 1;
  }

  input {
    min-width: 200px;
    flex: 1;
  }

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const CustomRow = styled(Row)`
  padding: 20px;
  display: flex;
  background-color: ${(props) => props.theme.menuBackgroundColor};
  height: 100%;
`;

const AvatarContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  .avatar {
    display: none;
  }

  .initials {
    display: flex;
  }

  &:hover {
    .avatar {
      display: none;
    }

    .initials {
      display: flex;
    }
  }
`;

const HeaderRow = ({ content, showToast, expanded, setSettingsRetrieved }) => {
  const [showLogin, setShowLogin] = useState(false);
  const { settings } = useSettingsOutlet();

  const history = useHistory();
  const location = useLocation();

  const doSearch = (e) => {
    e.preventDefault();
    console.log(e.target[0].value);
    history.push("/search", { query: e.target[0].value });
  };

  console.log(settings);
  // console.log(user);

  return (
    <>
      <CustomRow expanded={expanded}>
        <SearchCol>
          <form onSubmit={doSearch} style={{ display: "flex", flex: 1 }}>
            <Input />
            <Button type="submit" factor="tertiary outline">
              search
            </Button>
          </form>

          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              // console.log({ isSignedIn, user, providerId });

              return (
                <>
                  {isSignedIn && (
                    <>
{/*
                        <Link to="/favorites">
                          <FavoritesButton>
                            <img src={buttons.like} />
                          </FavoritesButton>
                        </Link>
*/}
                      <DropdownToggle>
                        {settings.picture && (
                          <AvatarContainer>
                            <Avatar src={settings.picture} size="40px" />
                            <Initials
                              name={
                                settings.preferredName
                                  ? settings.preferredName
                                  : user.displayName
                              }
                              size={40}
                            />
                          </AvatarContainer>
                        )}
                        {!settings.picture && (
                          <Initials
                            name={
                              settings.preferredName
                                ? settings.preferredName
                                : user.displayName
                            }
                            size={40}
                          />
                        )}
                        <DropdownList>
                          <NavContainer factor="column">
                            <Link to={"/profile/" + settings.userId}>
                              <NavItem>View profile</NavItem>
                            </Link>
                            <Link to="/settings">
                              <NavItem>Settings</NavItem>
                            </Link>
                            <NavSeparator />
                            <NavItem
                              onClick={() => {
                                firebase.auth().signOut();
                              }}
                            >
                              Log Out
                            </NavItem>
                          </NavContainer>
                        </DropdownList>
                      </DropdownToggle>
                    </>
                  )}
                  {!isSignedIn && (
                    <Button
                      factor="primary"
                      type="button"
                      onClick={() => setShowLogin(true)}
                    >
                      log in
                    </Button>
                  )}
                </>
              );
            }}
          </FirebaseAuthConsumer>
        </SearchCol>
      </CustomRow>

      {showLogin && (
        <LoginModal
          showToast={showToast}
          onClose={() => setShowLogin(false)}
          setSettingsRetrieved={setSettingsRetrieved}
        />
      )}
    </>
  );
};

export default HeaderRow;

import React, { useState } from "react";
import styled from "styled-components";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import {PaddedContent, ScreenContainer, ScrollbarWrapper, StationsGrid} from "../layout";
import { useHistory } from "react-router-dom";

const CountryWrapper = styled.div`
  flex-basis: 20%;
  padding: 20px;
  box-sizing: border-box;
`;

const Country = styled.div`
  //box-shadow: 0 0 5px ${(props) => props.theme.borderColor};
  border-radius: 10px;
  height: calc(100% - 9px);
  min-height: 150px;
  overflow: hidden;
  transition: linear 0.05s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  :hover {
    //transform: scale(1.1);
    cursor: pointer;
    z-index: 11;

    .country-background {
      opacity: 1;
      filter: brightness(1.1);
    }
  }

  position: relative;
`;

const CountryBackground = styled.div`
  opacity: 0.8;
  width: 100%;
  height: 100%;
  background: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //filter: grayscale(1);

  //z-index: 2;
`;

const CountryName = styled.div`
  font-size: 17px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

const Explore = ({ stations, countries, onStationClick }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ScrollbarWrapper>
      <PerfectScrollbar>
        <PaddedContent>
          <StationsGrid>
            {countries.map((country) => (
              <CountryWrapper
                key={"list-item-" + country.code}
                onClick={() => {
                  history.push("/search", { countryFilter: country.code });
                }}
              >
                <Country>
                  <CountryName>{country.name}</CountryName>
                  {country.code && (
                    <LazyLoadComponent>
                      <CountryBackground
                        className="country-background"
                        background={
                          require("../../assets/flags/" +
                            country.code.toLowerCase() +
                            ".svg").default
                        }
                      />
                    </LazyLoadComponent>
                  )}
                </Country>
              </CountryWrapper>
            ))}
          </StationsGrid>
        </PaddedContent>
      </PerfectScrollbar>
    </ScrollbarWrapper>
  );
};

export default Explore;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  TextInput,
  SelectInput,
} from "singulier";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./layout.scss";
import { ASSET_FOLDER, enableCollectionCreator } from "../lib/config";
import { addToCollection } from "../lib/collectionCreator";

const radioPlaceholder = require("../assets/radio_placeholder.png").default;

export const Section = styled.div`
  background: #dfe6e9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  //box-shadow: rgb(204 204 204) 0px 0px 5px;
`;

export const ExpandableCol = styled.div`
  flex: 1;
`;

export const CollapsibleCol = styled.div`
  flex: 0;
  white-space: nowrap;
`;

export const Spinner = () => {
  return (
    <div style={{ width: "40px", height: "40px" }}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export const ScreenContainer = styled.div`
  padding: 0 40px 0 40px;
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1280px) {
    height: calc(100vh - 170px);
  }
`;

export const PaddedContent = styled.div`
  padding: 20px 40px;
  max-width: 75%;
  margin: 0 auto;

  @media (max-width: 1280px) {
    max-width: 90%;
  }
`;

export const ScreenTitle = styled.h1`
  margin-bottom: 0;
  font-size: 24px;
  font-weight: ${(props) => (props.selected ? "700" : "100")};
  display: inline;
  padding: ${(props) =>
    props.dir === "column"
      ? props.selected
        ? "0 40px 10px 37px"
        : "0 40px 10px 40px"
      : "20px 5px"};

  width: 100%;

  color: ${(props) => (props.selected ? props.theme.mainColor : "#cccccc")};
  border-left: ${(props) =>
    props.dir === "column" && props.selected
      ? "solid 3px " + props.theme.mainColor
      : "0"};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.mainColorHover};
  }

  @media (max-width: 1280px) {
    font-size: 24px;
  }
`;

export const Input = styled(TextInput)`
  background-color: ${(props) => props.theme.inputBackgroundColor};
  border-color: ${(props) => props.theme.inputBorderColor};
  color: ${(props) => props.theme.inputTextColor};
`;

export const Select = styled(SelectInput)`
  background-color: ${(props) => props.theme.inputBackgroundColor};
  border-color: ${(props) => props.theme.inputBorderColor};
  color: ${(props) => props.theme.inputTextColor};
`;

export const External = (props) => (
  <></> // <img src={require("../assets/external.svg").default} {...props} />
);

export const Loader = () => {
  return (
    <div className="loader-container">
      <div className="progress">
        <div className="indeterminate" />
      </div>
    </div>
  );
};

export const ImageWithFallback = ({ id, src, fallbackSrc, style }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <img
      id={id}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
      style={style}
    />
    // <img
    // />
  );
};

export const InfoButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  border: solid 1px #ffffff;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;

  &:hover {
    cursor: pointer;
    border: solid 2px #ffffff;
  }
`;

export const LikeButton = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  border: solid 1px #ffffff;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;

  &:hover {
    cursor: pointer;
    border: solid 2px #ffffff;
  }
`;

export const InfoButtonInline = styled.div`
  width: 24px;
  height: 24px;
  border: solid 1px #ffffff;
  border-radius: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  display: flex;
  z-index: 11;
  font-size: 14px;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    border: solid 2px #ffffff;
  }
`;

export const CollectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow-y: auto;
  justify-content: flex-start;

  @media (max-width: 1080px) {
    justify-content: space-around;
  }
`;

export const StationsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow-y: auto;
  justify-content: flex-start;

  // background: ${(props) => props.theme.slideshowBackground};
  // border-radius: 16px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  @media (max-width: 1080px) {
    justify-content: space-around;
  }
`;

export const StationWrapper = styled.div`
  flex-basis: 20%;
  padding: 20px;
  box-sizing: border-box;
  min-width: 150px;
  max-width: 220px;
`;

export const StationItem = styled.div`
  position: relative;
  min-height: 48px;
  //border: solid 1px ${(props) => props.theme.borderColor};
  box-shadow: 0 0 5px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.stationItemBackgroundColor};
  border-radius: 10px;
  // height: calc(100% - 9px);
  height: 100%;
  width: 100%;
  padding-top: calc(100% - 4px);
  overflow: hidden;
  transition: linear 0.05s;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.selected
      ? "outline: solid 2px " + props.theme.buttonColor + ";"
      : "outline: solid 2px #00000000;"}

  :hover {
    //box-shadow: 0 0 5px ${(props) => props.theme.stationItemShadowColor};
    //transform: scale(1.1);
    cursor: pointer;
    //z-index: 11;
    filter: brightness(1.1);

    .info-button {
      display: flex;
    }

    .station-title {
      display: flex;
    }
  }
`;

export const StationTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 48px;
  color: #ffffff;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  //text-align: center;
  //justify-content: center;
`;

const StationSquare = styled.div`
  background-color: #b53471;
  color: #ffffff;
  width: 100%;
  padding-top: calc(100% + 2px);
  position: absolute;
  overflow: hidden;
  top: 0;
`;

const StationSquareTitle = styled(StationTitle)`
  position: absolute;
  bottom: 6px;
  display: none;
`;

const StationSquareLogo = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  //transform: translateX(-50%) translateY(-50%) rotate(-10deg);
  transform: translateX(-50%) translateY(-50%);
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-shadow: 0 0 10px #000000;
`;

const squareColors = [
  "#6ab04c",
  "#eb4d4b",
  "#30336b",
  "#4834d4",
  "#22a6b3",
  "#f0932b",
  "#c44569",
  "#574b90",
  "#546de5",
  "#218c74",
];

const hashCode = (s) => {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash);
};

export const Station = (props) => {
  const { station, stationClick, showStationInfo, selected } = props;

  const [imgSrc, setImgSrc] = useState(station.image);
  const [bgImgSrc, setBgImgSrc] = useState(station.image);

  useEffect(() => {
    setImgSrc(station.image);
  }, [station]);

  useEffect(() => {
    setBgImgSrc(imgSrc);
  }, [imgSrc]);

  return (
    <StationWrapper key={"station-" + station.externalId} {...props}>
      <StationItem
        onClick={() => stationClick(station)}
        selected={selected}
        style={
          {
            // paddingTop: imgSrc ? "0" : "100%",
          }
        }
      >
        <InfoButton
          className="info-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showStationInfo(station);
          }}
        >
          i
        </InfoButton>
        {imgSrc && (
          <>
            {bgImgSrc !== "placeholder.png" && (
              <img
                src={
                  bgImgSrc !== "placeholder.png"
                    ? ASSET_FOLDER + bgImgSrc
                    : radioPlaceholder
                }
                onLoad={(e) => {
                  if (
                    e.target.naturalHeight < 150 ||
                    e.target.naturalWidth < 150
                  ) {
                    setBgImgSrc(null);
                  }
                }}
                onError={() => {
                  setBgImgSrc(null);
                }}
                style={{
                  height: "100%",
                  position: "absolute",
                  top: "50%",
                  left: "-20px",
                  minWidth: "100%",
                  transform: "translateY(-50%)",
                  filter: "blur(20px)",
                }}
              />
            )}

            <img
              src={
                imgSrc !== "placeholder.png"
                  ? ASSET_FOLDER + imgSrc
                  : radioPlaceholder
              }
              onLoad={(e) => {
                if (
                  e.target.naturalHeight < 150 ||
                  e.target.naturalWidth < 150
                ) {
                  setImgSrc(null);
                }
                if (
                  e.target.naturalHeight / e.target.naturalWidth > 0.9 &&
                  e.target.naturalHeight / e.target.naturalWidth < 1.1
                ) {
                  setBgImgSrc(null);
                }
              }}
              onError={() => {
                setImgSrc(null);
              }}
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />

            {!props.hideName && (
              <StationTitle className="station-title">
                {station.name}
              </StationTitle>
            )}
          </>
        )}

        {!imgSrc && (
          <>
            <StationSquare
              style={{
                backgroundColor:
                  squareColors[hashCode(station.name) % squareColors.length],
              }}
            >
              <StationSquareLogo>{station.name}</StationSquareLogo>
              <StationSquareTitle className="station-title">
                {station.name}
              </StationSquareTitle>
            </StationSquare>
          </>
        )}
      </StationItem>
      {enableCollectionCreator && (
        <button onClick={() => addToCollection(station)}>add</button>
      )}
    </StationWrapper>
  );
};

export const CollectionBadge = styled.div`
  background-color: ${({ colors }) => colors.color1};
`;

export const CollectionContainer = styled.div`
  flex-basis: calc(50% - 20px);
  box-sizing: border-box;

  margin: 10px;
  width: 100%;

  margin-bottom: 20px;
  border-radius: 20px;
  //border: solid 1px ${({ colors }) => colors.color1};
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: ${({ colors }) => colors.color1};
  // background: linear-gradient(
  //   90deg,
  //   ${({ colors }) => colors.color1} 0,
  //   ${({ colors }) => colors.color2} 100%,
  //   #ffffff00 120px
  // );
  background-color: ${({ colors }) => colors.color1};
  background-color: ${(props) =>
    props.theme.collectionBackgroundColor
      ? props.theme.collectionBackgroundColor
      : props.theme.menuBackgroundColor};
  
  display: flex;
  flex-direction: row;
  height: fit-content;
}
`;

export const CollectionTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: ${({ colors }) => colors.title};
  color: ${(props) => props.theme.textColor};
`;

export const CollectionDescription = styled.div`
  font-size: 14px;
  text-align: center;
  // color: ${({ colors }) => colors.text};
  //
  // a {
  //   color: ${({ colors }) => colors.text};
  //   font-weight: bold;
  // }

  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 10px;
  color: #ffffff;

  a {
    color: #ffffff;
    font-weight: bold;
  }
`;

export const CollectionButton = styled(Button)`
  margin-top: 10px;
  //width: 100%;

  background-color: ${({ colors }) => colors.color1};
  color: ${({ colors }) => colors.title};
  filter: brightness(1.1);

  &:hover {
    background-color: ${({ colors }) => colors.color2};
  }
`;

export const centeredStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const LazyLoadImageBg = () => {
  return <></>;
};

export const ConfirmModal = ({
  title,
  message,
  type,
  yesLabel,
  noLabel,
  onYesCb,
  onNoCb,
}) => {
  return (
    <Modal onClose={onNoCb}>
      <ModalHeader>{title ?? "Confirm"}</ModalHeader>
      <ModalContent>{message}</ModalContent>
      <ModalFooter>
        <Button factor={"tertiary"} onClick={onNoCb}>
          {noLabel ?? "no"}
        </Button>
        <Button factor={type ?? "primary"} onClick={onYesCb}>
          {yesLabel ?? "yes"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ScrollbarWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
`;

import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  Separator,
  Checkbox,
} from "singulier";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import styled from "styled-components";
import { useToastOutlet } from "../../common/toastContext/toastContext";
import { getCookie } from "../../lib/utils";
import { bCreateUser, bPostIdTokenToSessionLogin } from "../../backend/auth";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { useSettingsOutlet } from "../../common/settingsContext/settingsContext";
import { bGetSettings } from "../../backend/settings";
import {Input} from "../layout";

const GoogleButton = styled.button`
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;

  padding: 12px 16px 12px 42px;
  border: solid 1px #cccccc;

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 15px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
`;

const LoginModal = ({ showToast, onClose, inline, setSettingsRetrieved }) => {
  const [isLogin, setIsLogin] = useState(true);
  const showAppToast = useToastOutlet();
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

  const { isLoading, setIsLoading } = useLoadingOutlet();
  const { setSettings } = useSettingsOutlet();


  const loginWithGoogle = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ user }) => {
        return user.getIdToken().then((idToken) => {
          const csrfToken = getCookie("csrfToken");
          return bPostIdTokenToSessionLogin(idToken, csrfToken).then(
            (result) => {
              bGetSettings().then((userSettings) => {
                setIsLoading(false);
                if (userSettings !== "Invalid token.") {
                  setSettings(userSettings);
                  setSettingsRetrieved(true);
                } else {
                }
              });
            }
          );
        });
      })
      // .then(() => {
      //   return firebase.auth().signOut();
      // })
      .catch((err) => {
        setIsLoading(false);
        showAppToast(err.message, "danger");
      });
  };

  const signUpWithGoogle = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ user }) => {
        return user.getIdToken().then((idToken) => {
          const csrfToken = getCookie("csrfToken");
          return bCreateUser(idToken, csrfToken).then(() => {
            bGetSettings().then((userSettings) => {
              setIsLoading(false);
              if (userSettings !== "Invalid token.") {
                setSettings(userSettings);
                setSettingsRetrieved(true);
              } else {
              }
            });
          });
        });
      })
      // .then(() => {
      //   return firebase.auth().signOut();
      // })
      .catch((err) => {
        showAppToast(err.message, "danger");
      });
  };

  const loginWithEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    if (!object.email || !object.password) {
      showAppToast("Please fill in all inputs.", "danger");
      return;
    }

    setIsLoading(true);
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(object.email, object.password)
        .then(({ user }) => {
          return user.getIdToken().then((idToken) => {
            const csrfToken = getCookie("csrfToken");
            return bPostIdTokenToSessionLogin(idToken, csrfToken).then(() => {
              bGetSettings().then((userSettings) => {
                setIsLoading(false);
                setSettings(userSettings);
              });
            });
          });
        })
        // .then(() => {
        //   return firebase.auth().signOut();
        // })
        .catch((err) => {
          setIsLoading(false);
          showAppToast(err.message, "danger");
        });
    } catch (e) {
      setIsLoading(false);
      showAppToast("", "danger");
    }
  };

  const signupWithEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.target);
    const object = {};
    data.forEach((value, key) => (object[key] = value));

    console.log(object);
    if (
      !object.email ||
      !object.password1 ||
      !object.password2 ||
      !object.name
    ) {
      showAppToast("Please fill in all inputs.", "danger");
      return;
    }

    if (object.termsOfUse !== "on") {
      showAppToast("You must agree with the terms of use.", "danger");
      return;
    }

    if (object.password1 !== object.password2) {
      showAppToast("The two passwords don't match.", "danger");
      return;
    }

    setIsLoading(true);
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(object.email, object.password1)
        .then(({ user }) => {
          user.updateProfile({
            displayName: object.name,
          });

          return user.getIdToken().then((idToken) => {
            const csrfToken = getCookie("csrfToken");
            return bCreateUser(idToken, csrfToken).then(() => {
              bGetSettings().then((userSettings) => {
                setIsLoading(false);
                setSettings(userSettings);
              });
            });
          });
        })
        .catch((err) => {
          setIsLoading(false);
          showAppToast(err.message, "danger");
        });
    } catch (e) {
      setIsLoading(false);
      showAppToast("", "danger");
    }
  };

  return (
    <>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          if (isSignedIn) {
            showToast("Welcome, " + user.displayName);

            onClose();

            return <></>;
          }

          return (
            <Modal onClose={onClose} factor={inline ? "inline" : ""}>
              {isLogin && (
                <>
                  <ModalHeader>Log in</ModalHeader>
                  <ModalContent>
                    <div>Please enter your credentials below.</div>
                    <Separator size="20px" />
                    <form onSubmit={loginWithEmail}>
                      <div>Email</div>
                      <Input name="email" type="email" factor="block" />
                      <Separator size="10px" />
                      <div>Password</div>
                      <Input
                        name="password"
                        type="password"
                        factor="block"
                      />
                      <Separator size="10px" />
                      <Button factor="primary block" type="submit">
                        log in
                      </Button>
                    </form>
                    <Separator size="10px" />
                    <Button
                      factor="tertiary block"
                      type="button"
                      onClick={() => setIsLogin(false)}
                    >
                      create a new account
                    </Button>
                    <Separator size="10px" />
                    <Button factor="tertiary block" onClick={loginWithGoogle}>
                      log in with google
                    </Button>
                  </ModalContent>
                </>
              )}

              {!isLogin && (
                <>
                  <ModalHeader>Sign up</ModalHeader>
                  <ModalContent>
                    <div>Please enter your desired credentials below.</div>
                    <Separator size="20px" />
                    <form onSubmit={signupWithEmail}>
                      <div>Name</div>
                      <Input name="name" type="text" factor="block" />
                      <Separator size="10px" />
                      <div>Email</div>
                      <Input name="email" type="email" factor="block" />
                      <Separator size="10px" />
                      <div>Password</div>
                      <Input
                        name="password1"
                        type="password"
                        factor="block"
                      />
                      <Separator size="10px" />
                      <div>Repeat password</div>
                      <Input
                        name="password2"
                        type="password"
                        factor="block"
                      />
                      <Separator size="10px" />
                      <Checkbox
                        id="termsOfUse"
                        name="termsOfUse"
                        label={
                          <div>
                            by creating the account, you agree with the{" "}
                            <a href="#" className="link">
                              terms of use
                            </a>
                            .
                          </div>
                        }
                      />
                      <Separator size="10px" />
                      <Button factor="primary block" type="submit">
                        sign up
                      </Button>
                    </form>
                    <Separator size="10px" />
                    <Button factor="tertiary block" onClick={signUpWithGoogle}>
                      sign up with google
                    </Button>
                    <Separator size="10px" />
                    <Button
                      factor="tertiary block"
                      type="button"
                      onClick={() => setIsLogin(true)}
                    >
                      log into your existing account
                    </Button>
                  </ModalContent>
                </>
              )}
              <ModalFooter>
                <Button factor="tertiary block" onClick={onClose}>
                  explore without an account
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </FirebaseAuthConsumer>
    </>
  );
};

export default LoginModal;

import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const SettingsCtx = React.createContext();

export function SettingsContext({ children }) {
  const [settings, setSettings] = useState(false);
  const ctx = useMemo(
    () => ({ settings: settings, setSettings: setSettings }),
    [settings]
  );

  return <SettingsCtx.Provider value={ctx}>{children}</SettingsCtx.Provider>;
}

export function useSettingsOutlet() {
  const settingsCtx = useContext(SettingsCtx);
  return {
    settings: settingsCtx.settings,
    setSettings: settingsCtx.setSettings,
  };
}

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    font-weight: 100;
  }

  .link {
    color: ${(props) => props.theme.mainColor};

    &:hover {
      text-decoration: underline;
    }
  }

  .progress {
    background-color: ${(props) => props.theme.backgroundColor};
    filter: opacity(0.7);
  }
  .progress .indeterminate {
    background-color: ${(props) => props.theme.badgeColor ? props.theme.badgeColor : props.theme.mainColor};
  }

  .modal {
    background: ${(props) => props.theme.modalColor} !important;
  }

  .modal-footer {
    background: ${(props) => props.theme.modalFooterColor} !important;
  }

  .modal-close-button {
    background-color: ${(props) => props.theme.primaryButtonColor ? props.theme.primaryButtonColor : props.theme.mainColor} !important;
    border-color: ${(props) => props.theme.primaryButtonColor ? props.theme.primaryButtonColor : props.theme.mainColor} !important;
    color: ${(props) => props.theme.buttonColor} !important;
  }

  .button-tertiary {
    color: ${(props) => props.theme.textColor} !important;
  }
  
  .button-primary {
    background-color: ${(props) => props.theme.primaryButtonColor ? props.theme.primaryButtonColor : props.theme.mainColor} !important;
    border-color: ${(props) => props.theme.primaryButtonColor ? props.theme.primaryButtonColor : props.theme.mainColor} !important;
    color: ${(props) => props.theme.buttonColor} !important;

    &.button-outline {
      background-color: rgba(0, 0, 0, 0) !important;
      transition: none !important;
      color: ${(props) => props.theme.mainColorHover} !important;
    }

    &:hover {
      background-color: ${(props) => props.theme.primaryButtonHoverColor ? props.theme.primaryButtonHoverColor : props.theme.mainColorHover} !important;
      border-color: ${(props) => props.theme.primaryButtonHoverColor ? props.theme.primaryButtonHoverColor : props.theme.mainColorHover} !important;
      color: ${(props) => props.theme.buttonColor} !important;
    }
  }

  .button-danger {
    background-color: ${(props) => props.theme.dangerColor} !important;
    border-color: ${(props) => props.theme.dangerColor} !important;

    &:hover {
      background-color: ${(props) => props.theme.dangerColorHover} !important;
      border-color: ${(props) => props.theme.dangerColorHover} !important;
    }
  }

  .alert-danger {
    background-color: ${(props) => props.theme.dangerColor} !important;
    border-color: ${(props) => props.theme.dangerColor} !important;
  }

  .pill {
    color: ${(props) => props.theme.mainColor} !important;
  }

  .pill-active {
    background-color: ${(props) => props.theme.mainColor} !important;
    border-color: ${(props) => props.theme.mainColor} !important;
    color: ${(props) => props.theme.buttonColor} !important;
  }

  .form-radio-container .form-radio-input:checked + label:after,
  .form-radio-container .form-radio-input:not(:checked) + label:after {
    background: ${(props) => props.theme.mainColor} !important;
  }

  .form-checkbox-container .form-checkbox-input:checked + label:after,
  .form-checkbox-container .form-checkbox-input:not(:checked) + label:after {
    background: ${(props) => props.theme.mainColor} !important;
  }

  .form-radio-container .form-radio-input:checked + label:before,
  .form-radio-container .form-radio-input:not(:checked) + label:before {
    background: ${(props) => props.theme.inputBackgroundColor} !important;
    border-color: ${(props) => props.theme.inputBorderColor} !important;
  }

  .form-checkbox-container .form-checkbox-input:checked + label:before,
  .form-checkbox-container .form-checkbox-input:not(:checked) + label:before {
    background: ${(props) => props.theme.inputBackgroundColor} !important;
    border-color: ${(props) => props.theme.inputBorderColor} !important;
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    background: ${(props) => props.theme.mainColor} !important; /* Green background */
  }

  .slider::-moz-range-thumb {
    background: ${(props) => props.theme.mainColor} !important; /* Green background */
  }

  .carousel-root {
    border-radius: 10px;
    box-shadow: 0 0 100px 10px ${(props) => props.theme.badgeColor ? props.theme.badgeColor : props.theme.mainColor};
  }

  .ps .ps__rail-y:hover,  .ps .ps__rail-y.ps--clicking {
    background: ${(props) => props.theme.menuBackgroundColor} !important;
  }

  .ps .ps__rail-y.ps--clicking .ps__thumb-y {
    background: ${(props) => props.theme.mainColor} !important;
  }

  .playlist-container {
    background-color: ${(props) => props.theme.menuBackgroundColor};
  }
`;

export default GlobalStyle;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import {
  Separator,
  Initials,
} from "singulier";
import {
  ScreenContainer,
  Station,
  StationsGrid,
} from "../layout";
import { useSettingsOutlet } from "../../common/settingsContext/settingsContext";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { useToastOutlet } from "../../common/toastContext/toastContext";
import { bGetProfile } from "../../backend/profile";

const JoinedDate = styled.div`
  font-size: 14px;
  line-height: 14px;
`;

const ProfileName = styled.div`
  font-size: 36px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding: 20px 40px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.menuBackgroundColor};
  margin-top: 80px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: -40px;
`;

const PrivateProfile = styled.div`
  font-size: 17px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Profile = ({ onStationClick, onShowStationInfo }) => {
  const { settings, setSettings } = useSettingsOutlet();
  const { isLoading, setIsLoading } = useLoadingOutlet();
  const showAppToast = useToastOutlet();

  let { id } = useParams();

  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    bGetProfile(id).then((data) => {
      setProfileData(data);
    });
  }, [id]);

  // console.log(profileData);
  // console.log(settings);

  return (
    <ScreenContainer style={{padding: 0}}>
      {profileData && (
        <PerfectScrollbar>
          <Wrapper>
            <ProfileName>{profileData.name}</ProfileName>
            {!profileData.privateProfile && (
              <JoinedDate>
                joined <Moment date={profileData.joinedOn} fromNow />
              </JoinedDate>
            )}
            <AvatarWrapper>
              <Initials name={profileData.name} size="120px" />
            </AvatarWrapper>
            {!profileData.privateProfile && (
              <>
                <Separator size="20px" />
                <h1>Favorites</h1>
                <StationsGrid style={{ justifyContent: "space-around" }}>
                  {profileData.favorites.map((station) => (
                    <Station
                      key={"station-" + station.externalId}
                      station={station}
                      stationClick={onStationClick}
                      showStationInfo={onShowStationInfo}
                    />
                  ))}
                </StationsGrid>
              </>
            )}
            {profileData.privateProfile && (
              <PrivateProfile>This profile is private.</PrivateProfile>
            )}
          </Wrapper>
        </PerfectScrollbar>
      )}
    </ScreenContainer>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Separator } from "singulier";
import { ScreenTitle } from "../layout";
import "firebase/auth";
import LoginModal from "../login-modal/LoginModal";

const CustomCol = styled.div`
  display: flex;
  flex-direction: ${({ dir }) => dir};
  // TODO
  padding: ${({ dir }) =>
    dir === "column" ? "80px 0 0 0" : "20px 20px 20px 100px"};
  min-width: 350px;
  background-color: ${(props) => props.theme.menuBackgroundColor};
  height: 100%;
`;

const MenuCol = ({ showToast, expanded, dir, setSettingsRetrieved }) => {
  const [showLogin, setShowLogin] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const doSearch = (e) => {
    e.preventDefault();
    console.log(e.target[0].value);
    history.push("/search", { query: e.target[0].value });
  };

  return (
    <>
      <CustomCol expanded={expanded} dir={dir}>
        <Link to="/">
          <ScreenTitle
            dir={dir}
            selected={
              location.pathname === "/highlights" || location.pathname === "/"
            }
          >
            Highlights
          </ScreenTitle>
        </Link>
        <Link to="/stations">
          <ScreenTitle selected={location.pathname === "/stations"} dir={dir}>
            Stations
          </ScreenTitle>
        </Link>
        <Link to="/discover">
          <ScreenTitle selected={location.pathname === "/discover"} dir={dir}>
            Discover
          </ScreenTitle>
        </Link>
        <Separator size="20px" />
        <Link to="/favorites">
          <ScreenTitle selected={location.pathname === "/favorites"} dir={dir}>
            Favorites
          </ScreenTitle>
        </Link>
        <Link to="/collections">
          <ScreenTitle selected={location.pathname === "/collections"} dir={dir}>
            Collections
          </ScreenTitle>
        </Link>
        {/*<Link to="/settings">*/}
        {/*  <ScreenTitle selected={location.pathname === "/settings"} dir={dir}>*/}
        {/*    Settings*/}
        {/*  </ScreenTitle>*/}
        {/*</Link>*/}

        {location.pathname === "/search" && (
          <>
            {dir === "column" && <Separator size="20px" />}
            <Link to="/search">
              <ScreenTitle selected={true} dir={dir}>
                Search
              </ScreenTitle>
            </Link>
          </>
        )}
      </CustomCol>

      {showLogin && (
        <LoginModal
          showToast={showToast}
          onClose={() => setShowLogin(false)}
          setSettingsRetrieved={setSettingsRetrieved}
        />
      )}
    </>
  );
};

export default MenuCol;

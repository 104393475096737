import axios from "axios";
import { API_URL } from "./config";

const METADATA_SERVER_URL = `${API_URL}/api`;

export const bAddActivity = (activity, stationId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${METADATA_SERVER_URL}/activity/${activity}/${stationId}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

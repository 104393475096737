import axios from "axios";
import { API_URL } from "./config";

const SERVER_URL = `${API_URL}/api`;

export const bGetFavorites = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/favorites/`, {
        withCredentials: true,
      })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bAddToFavorites = (stationId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${SERVER_URL}/favorites/` + stationId,
        {},
        {
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

export const bRemoveFromFavorites = (stationId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${SERVER_URL}/favorites/` + stationId,
        {},
        {
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

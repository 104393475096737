export const firebaseConfig = {
  apiKey: "AIzaSyAb8LYB1nmGddI7ZxNc_aOJGxGeExtGcBU",
  authDomain: "project-total-314617.firebaseapp.com",
  projectId: "project-total-314617",
  storageBucket: "project-total-314617.appspot.com",
  messagingSenderId: "475371512727",
  appId: "1:475371512727:web:e4a7cb5b56c49b20131ad2",
  measurementId: "G-G7HW7RDC5N"
};


export const enableCollectionCreator = false;

export const ASSET_FOLDER = 'http://static.soundresidence.com/radiotal/';

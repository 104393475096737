import axios from "axios";
import { API_URL } from "./config";

const SERVER_URL = `${API_URL}/api`;

export const bGetStatus = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${SERVER_URL}/status/web`, {}).then(
      (response) => {
        return resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

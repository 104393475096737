import React from "react";

import styled from "styled-components";

const images = {
  noResults: require("../../assets/illustrations/music file2-09.svg").default,
};

const PageStateContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Illustration = styled.img`
  max-width: 90%;
  max-height: calc(100vh - 400px);
  width: 100%;
  height: 100vh;
`;

const Title = styled.div`
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
`;

const Message = styled.div`
  font-size: 17px;
  text-align: center;
`;

const PageState = ({ title, message, image }) => {
  return (
    <PageStateContainer>
      <Illustration src={images[image] || images.noResults} />
      <Title>{title}</Title>
      <Message>{message}</Message>
    </PageStateContainer>
  );
};

export default PageState;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {
  PaddedContent,
  ScreenContainer, ScrollbarWrapper,
} from "../layout";
import { bGetTags } from "../../backend/stations";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { hashCode, squareColors } from "../../lib/utils";

const NavigateContainer = styled.div`
  position: fixed;
  bottom: 90px;
  left: 0;
  width: 100%;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;

  button {
    margin: 0 5px;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  padding: 5px 10px;
  border: solid 1px ${(props) => props.theme.tagColor};
  border-radius: 20px;
  color: ${(props) => props.theme.tagColor};
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  margin: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const tags = [
  "pop",
  "news",
  "rock",
  "music",
  "talk",
  "public radio",
  "classical",
  "dance",
  "christian",
  "80s",
  "top 40",
  "oldies",
  "jazz",
  "adult contemporary",
  "greek",
  "community radio",
  "folk",
  "classic rock",
  "hits",
  "electronic",
  "country",
  "90s",
  "local news",
  "alternative",
  "classic hits",
  "house",
  "70s",
  "commercial",
  "hiphop",
  "npr",
  "chillout",
  "indie",
  "university radio",
  "soul",
  "information",
  "world music",
  "ambient",
];

const TagItemWrapper = styled.div`
  flex-basis: 20%;
  padding: 10px;
  box-sizing: border-box;
  max-width: 400px;
`;

const TagItem = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(1.1);
  }
`;

const TagText = styled.div`
  font-weight: lighter;
  //position: absolute;
  //right: 10px;
  //bottom: 10px;
  color: #ffffff;
  text-align: center;
  max-width: 100%;
  font-size: 24px;
  text-transform: capitalize;
`;

const TagsList = ({ onStationClick, onShowStationInfo }) => {
  const [tags, setTags] = useState([]);

  const { isLoading, setIsLoading } = useLoadingOutlet();

  useEffect(() => {
    setIsLoading(true);
    bGetTags(100).then((t) => {
      setIsLoading(false);
      if (Array.isArray(t)) {
        setTags(t);
      }
    });
  }, []);

  return (
    <ScreenContainer style={{ padding: 0 }}>
      <ScrollbarWrapper>
        <PerfectScrollbar>
          <PaddedContent>
            <TagsContainer>
              {tags.map((tag) => (
                <Link
                  to={{
                    pathname: "/search",
                    state: {
                      query: tag,
                    },
                  }}
                  style={{ flexBasis: "20%" }}
                >
                  <TagItemWrapper>
                    <TagItem
                      color={squareColors[hashCode(tag) % squareColors.length]}
                    >
                      <TagText>{tag}</TagText>
                    </TagItem>
                  </TagItemWrapper>
                </Link>
              ))}
            </TagsContainer>
          </PaddedContent>
        </PerfectScrollbar>
      </ScrollbarWrapper>
    </ScreenContainer>
  );
};

export default TagsList;

import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const SpinnerComp = styled.svg`
  animation: rotate 2s linear infinite;
  width: 25px;
  height: 25px;

  & .path {
    stroke: #777777;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const LoadingCtx = React.createContext();

export function Spinner() {
  const { isLoading, setIsLoading } = useContext(LoadingCtx);

  return (
    isLoading && (
      <SpinnerComp>
        <circle
          className="path"
          cx="12"
          cy="12"
          r="10"
          fill="none"
          strokeWidth="2"
        />
      </SpinnerComp>
    )
  );
}

export function LoadingContext({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const ctx = useMemo(() => ({ isLoading, setIsLoading }), [isLoading]);

  return <LoadingCtx.Provider value={ctx}>{children}</LoadingCtx.Provider>;
}

export function useLoadingOutlet() {
  const loadingCtx = useContext(LoadingCtx);
  return {
    isLoading: loadingCtx.isLoading,
    setIsLoading: loadingCtx.setIsLoading
  };
}

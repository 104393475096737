import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  centeredStyle,
  CollapsibleCol, CollectionButton, CollectionContainer, CollectionDescription, CollectionGrid, CollectionTitle,
  ExpandableCol,
  PaddedContent, ScrollbarWrapper,
} from "../layout";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { bGetCollections } from "../../backend/collection";

const Collections = ({
  onViewCollection,
}) => {
  const { setIsLoading } = useLoadingOutlet();

  const [collections, setCollections] = useState([]);

  console.log(collections)
  useEffect(() => {
    setIsLoading(true);
    bGetCollections().then((list) => {
      setIsLoading(false);
      setCollections(list);
    });
  }, []);

  return (
    <ScrollbarWrapper>
      <PerfectScrollbar>
        <PaddedContent>
          {Array.isArray(collections) && (
            <div style={{ marginTop: "20px" }}>
              <CollectionGrid>
                {collections.filter(c => c.prettyId).map((collection, key) => {
                  return (
                    <CollectionContainer
                      key={"collection-" + collection.id}
                      colors={JSON.parse(collection.colors || "{}")}
                    >
                      <ExpandableCol
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "10px",
                        }}
                      >
                        <CollectionTitle
                          colors={JSON.parse(collection.colors || "{}")}
                        >
                          {collection.name}
                        </CollectionTitle>
                        {collection.description && (
                          <CollectionDescription
                            colors={JSON.parse(collection.colors || "{}")}
                          >
                            {collection.description}
                          </CollectionDescription>
                        )}
                      </ExpandableCol>
                      <CollapsibleCol
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "10px",
                        }}
                      >
                        <div style={{width: "100%", ...centeredStyle}}>
                        <CollectionButton
                          colors={JSON.parse(
                            collection.colors ||
                              '{"color1": "#0984e3", "text":"#ffffff"}'
                          )}
                          onClick={() => onViewCollection(collection.prettyId)}
                        >
                          view collection
                        </CollectionButton>
                        </div>
                      </CollapsibleCol>
                    </CollectionContainer>
                  );
                })}
              </CollectionGrid>
            </div>
          )}
        </PaddedContent>
      </PerfectScrollbar>
    </ScrollbarWrapper>
  );
};

export default Collections;

import axios from "axios";
import { API_URL } from "./config";

const METADATA_SERVER_URL = `${API_URL}/api`;

export const getStreamInfo = (url) => {
  console.log("URL: ", url);
  if (!url) {
    return new Promise((resolve, reject) => resolve(null));
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${METADATA_SERVER_URL}/stream/info`,
        {
          url: url,
        },
        { withCredentials: true }
      )
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

// // URL to a known ICY stream
// var url ='http://80s80s.hoerradar.de/80s80s-mweb-mp3-hq?sABC=60n4550p%230%236p0pqp0n25q8qssq8oqso890on0n3rn5%23fgernzf.80f80f.qr&=&amsparams=playerid:streams.80s80s.de;skey:1621382412';

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getColorFromNameHash = (name) => {
  if (!name) return "#353b48";

  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const squareColors = [
  "#6ab04c",
  "#eb4d4b",
  "#30336b",
  "#4834d4",
  "#22a6b3",
  "#f0932b",
  "#c44569",
  "#574b90",
  "#546de5",
  "#218c74",
  "#40739e",
  "#273c75",
  "#192a56",
  "#079992",
  "#b71540",
  "#833471",
  "#F79F1F",
  // '#1289A7',
  // '#A3CB38',
  // '#006266'
];

export const hashCode = (s) => {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash);
};

import React, { useState, useRef, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactHlsPlayer from "react-hls-player";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row, Button } from "singulier";
import {
  centeredStyle,
  CollapsibleCol,
  CollectionGrid,
  ExpandableCol,
  ImageWithFallback,
  InfoButtonInline,
  Spinner,
} from "../layout";
import { getStreamInfo } from "../../backend/meta";
import "./Player.scss";
// import {
//   addToFavorites,
//   getFavorites,
//   removeFromFavorites,
// } from "../../backend/storage";
import { bAddToFavorites, bRemoveFromFavorites } from "../../backend/favorites";
import { useLoadingOutlet } from "../../common/loadingContext/loadingContext";
import { useSettingsOutlet } from "../../common/settingsContext/settingsContext";
import { ASSET_FOLDER, enableCollectionCreator } from "../../lib/config";
import {
  addToCollection,
  dumpCollection,
  resetCollection,
} from "../../lib/collectionCreator";

const radioPlaceholder = require("../../assets/radio_placeholder.png").default;

const buttons = {
  play: require("../../assets/play.svg").default,
  pause: require("../../assets/pause.svg").default,
  next: require("../../assets/next.png").default,
  previous: require("../../assets/previous.png").default,
  up: require("../../assets/up.png").default,
  down: require("../../assets/down.png").default,
  like: require("../../assets/star.png").default,
  unlike: require("../../assets/star-filled.png").default,
};

const PlayerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ expanded }) => (expanded ? "100vh" : "90px")};
  background: ${(props) => props.theme.playerColor};
  display: flex;
  flex-direction: ${({ expanded }) => (expanded ? "column" : "row")};
  padding: 0 20px;
  color: ${(props) => props.theme.playerTextColor};
  align-items: center;
  box-shadow: 0 0 5px #333333;
  z-index: ${({ expanded }) => (expanded ? 50 : 5)};
  transition: all 0.2s ease-in-out;
  overflow: ${({ expanded }) => (expanded ? "hidden" : "visible")};
`;

const RadioIcon = styled.img`
  width: 64px;
  height: 64px;
  background-color: aliceblue;
  padding: 2px;
  box-shadow: 0 0 5px #777777;
  border: solid 2px #ffffff;

  transform: scale(1.5);
  transform-origin: bottom left;
  margin-right: 32px;
  z-index: 12;
`;

const RadioTitle = styled.div`
  color: #ffffff;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  button {
    margin-right: 10px;
  }
`;

const RadioLink = styled.div`
  color: #ffffff;
  font-size: 14px;
`;

const RadioInfo = styled(ExpandableCol)`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const RadioInfoExpanded = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-shadow: 0 0 5px black;
`;

const RadioTitleExpanded = styled.div`
  color: #ffffff;
  font-size: 48px;
`;

const RadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  button {
    border: solid 2px #ffffff !important;
    background: rgba(0, 0, 0, 0) !important;
    margin-right: 10px;
    color: #ffffff !important;

    &:hover {
      background: rgba(0, 0, 0, 0) !important;
      border: solid 2px #ffffff !important;
      filter: brightness(0.8);
    }
  }
`;

const RadioButton = styled.img`
  height: 48px;
  width: 48px;

  filter: invert(1);
  cursor: pointer;

  transform: ${({ rotated }) => (rotated ? "rotate(180deg)" : "rotate(0deg)")};

  transition: all 0.3s ease-in-out;

  :hover {
    filter: invert(0.8);
  }
`;

const LoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: ${({ transparent }) =>
    transparent ? "rgba(0,0,0,0)" : "rgba(0, 0, 0, 0.3)"};
`;

const SongTitle = styled(CollapsibleCol)`
  display: flex;
  color: #ffffff;
  font-size: 19px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  user-select: text;
  max-width: 40vw;
  overflow: hidden;
  text-overflow: ellipsis;

  img {
    height: 18px;
    width: 18px;
    filter: invert(1);
    margin-right: 10px;
  }
`;

const BackgroundDiv = styled.div`
  position: absolute;
  z-index: -1;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //filter: blur(50px);
  top: -50px;
  left: -50px;
  right: -50px;
  bottom: -50px;
  filter: blur(25px) grayscale(0.8);
`;

const AppLogo = styled.img`
  position: absolute;
  left: 24px;
  top: 24px;
  height: 16px;
`;

const LikeButton = styled.div`
  cursor: pointer;
  width: 26px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  img {
    filter: invert(1);
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

const ListItem = styled.div`
  width: 100%;
  padding: 8px;
  color: ${(props) =>
    props.selected ? props.theme.mainColor : props.theme.textColor};
  background-color: ${(props) => props.theme.backgroundColor};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
`;

let metadataInterval;
let screensaverInterval;

const setTitle = (title) => {
  document.title = title;
};

const Player = ({
  station,
  showToast,
  onShowStationInfo,
  userFavorites,
  selectNextStation,
  selectPreviousStation,
  selectStationByIndex,
  onViewCollection,
  collection = { stations: [] },
  isExternal,
}) => {
  const audioItemRef = useRef(null);
  const audioHlsRef = useRef(null);

  useEffect(() => {
    // audioItemRef.current.audioEl.current.onPlaying =() => {
    //   console.log("PLAYING");
    // };
  }, audioItemRef.current);

  console.log(station);

  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [volume, setVolume] = useState(100);
  const [expanded, setExpanded] = useState(false);
  const [favorites, setFavorites] = useState(userFavorites);
  const [isPip, setIsPip] = useState(false);
  const [showList, setShowList] = useState(false);
  const [pipVideo, setPipVideo] = useState(null);

  const pipAvailable = !!document.pictureInPictureEnabled;

  const { setIsLoading: setIsLoadingOutlet } = useLoadingOutlet();
  const { settings, setSettings } = useSettingsOutlet();

  const isInFavorites = (stationId) => {
    return favorites.filter((f) => f.id === stationId).length > 0;
  };

  const addToFavorites = (station) => {
    setIsLoadingOutlet(true);
    bAddToFavorites(station.id).then((data) => {
      setIsLoadingOutlet(false);
      if (data.success) {
        showToast("Added to favorites.");
        setFavorites([...favorites, station]);
      }
    });
  };

  const removeFromFavorites = (stationId) => {
    setIsLoadingOutlet(true);
    bRemoveFromFavorites(stationId).then((data) => {
      setIsLoadingOutlet(false);
      if (data.success) {
        showToast("Removed from favorites.");
        setFavorites(favorites.filter((f) => f.id !== stationId));
      }
    });
  };

  // useEffect(() => {
  //   bGetFavorites().then((favs) => {
  //     console.log("aici2");
  //     setFavorites(favs);
  //   });
  // }, []);
  //
  const play = () => {
    if (station.isHls) {
      console.log(audioHlsRef.current);
      audioHlsRef.current.play();
    } else {
      console.log(audioItemRef.current.audioEl);
      audioItemRef.current.audioEl.current.play();
    }
    //audioItemRef.current.play();
  };

  const pause = () => {
    if (station.isHls) {
      audioHlsRef.current.pause();
    } else {
      audioItemRef.current.audioEl.current.pause();
    }
  };

  useEffect(() => {
    if (isExternal) {
      clearInterval(metadataInterval);
      return;
    }
    setMetadata(null);

    setIsPlaying(false);
    if (station) {
      setTitle(station.name + " - Radiotal");
      setIsLoading(true);
    } else {
      setTitle("Radiotal");
    }

    clearInterval(metadataInterval);

    metadataInterval = setInterval(() => {
      getStreamInfo(station.streamUrl).then((data) => {
        console.log(data);
        setMetadata(data);
      });
    }, 10000);

    if (isPip) {
      showPipWindow();
      // console.log(pipVideo)
      // document.exitPictureInPicture();
      // setIsPip(false);
    }
  }, [station]);

  useEffect(() => {
    if (metadata && metadata.StreamTitle) {
      setTitle(metadata.StreamTitle + " on " + station.name + " - Radiotal");
    }
  }, [metadata]);

  useEffect(() => {
    showToast("Volume " + volume);
    if (audioItemRef.current) {
      audioItemRef.current.audioEl.current.volume = volume / 100;
    }

    if (audioHlsRef.current) {
      audioHlsRef.current.volume = volume / 100;
    }
  }, [volume]);

  useEffect(() => {
    if (!isPlaying) {
      clearInterval(metadataInterval);
      clearInterval(screensaverInterval);
      return;
    }

    document.addEventListener("mousemove", onMouseMove, false);

    clearInterval(metadataInterval);
    metadataInterval = setInterval(() => {
      getStreamInfo(station.streamUrl).then((data) => {
        console.log(data);
        setMetadata(data);
      });
    }, 10000);

    return () => {
      document.removeEventListener("mousemove", onMouseMove, false);
    };
  }, [isPlaying]);

  useEffect(() => {
    document.removeEventListener("mousemove", onMouseMove, false);
    document.addEventListener("mousemove", onMouseMove, false);
  }, [isPlaying, settings.showScreenSaver]);

  const onKeyPress = (e) => {
    if (!settings.enableShortcuts) {
      return false;
    }

    if (e.target.tagName.toUpperCase() === "INPUT") {
      return;
    }

    if (e.keyCode === 77) {
      // m (mute)
      setVolume(0);
    }

    if (e.keyCode === 189) {
      // minus
      setVolume(Math.max(0, volume - 5));
    }

    if (e.keyCode === 187) {
      // plus
      setVolume(Math.min(100, volume + 5));
    }
  };

  const onMouseMove = () => {
    clearInterval(screensaverInterval);
    if (settings.showScreenSaver) {
      screensaverInterval = setInterval(() => {
        setExpanded(true);
      }, settings.showScreenSaver * 1000);
    }
  };

  const showPipWindow = async () => {
    const canvas = document.createElement("canvas");
    canvas.width = canvas.height = 512;

    const video = document.createElement("video");
    video.srcObject = canvas.captureStream();
    video.muted = true;

    // const stationImg = document.createElement("img");
    // stationImg.setAttribute("src", station.image);
    // stationImg.setAttribute("style", "width: 512px, height: 512px");
    // document.body.appendChild(stationImg);
    // console.log(stationImg);
    // const canvasImg = await html2canvas(stationImg);
    // canvasImg.width = canvasImg.height = 512;

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = ASSET_FOLDER + "/" + station.image;

    try {
      await image.decode();
      canvas.getContext("2d").drawImage(image, 0, 0, 512, 512);
    } catch (e) {
      const ctx = canvas.getContext("2d");

      const gradient = ctx.createLinearGradient(0, 0, 512, 512);

      gradient.addColorStop(0, "#667eea");
      gradient.addColorStop(1, "#764ba2");

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, 512, 512);

      // for (let i = 0; i < 60; i++) {
      //   for (let j = 0; j < 60; j++) {
      //     ctx.fillStyle = `rgb(${i * 5}, ${j * 5}, ${(i + j) * 50})`;
      //     ctx.fillRect(j * 20, i * 20, 20, 20);
      //   }
      // }

      ctx.fillStyle = "#ffffff";
      ctx.font = "72px Mukta";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("Radiotal", 256, 220);

      ctx.font = "48px Mukta";
      ctx.fillText(station.name, 256, 270);
    }

    video.onenterpictureinpicture = () => {
      setIsPip(true);
    };

    video.onleavepictureinpicture = () => {
      setIsPip(false);
    };

    await video.play();
    await video.requestPictureInPicture();

    if (collection && collection.stations.length > 1) {
      navigator.mediaSession.setActionHandler("nexttrack", function () {
        selectNextStation();
      });

      navigator.mediaSession.setActionHandler("previoustrack", function () {
        selectPreviousStation();
      });
    }

    navigator.mediaSession.setActionHandler("play", function () {
      play();
      if (document.pictureInPictureElement)
        document.pictureInPictureElement.play();
    });

    navigator.mediaSession.setActionHandler("pause", function () {
      pause();
      if (document.pictureInPictureElement)
        document.pictureInPictureElement.pause();
    });

    setPipVideo(video);
  };

  const onRequestPip = async () => {
    await showPipWindow();
  };

  useEffect(() => {
    document.removeEventListener("keydown", onKeyPress, false);
    document.addEventListener("keydown", onKeyPress, false);

    return () => {
      document.removeEventListener("keydown", onKeyPress, false);
    };
  }, [volume, settings.enableShortcuts]);

  if (!station) {
    return <></>;
  }

  return (
    <PlayerWrapper
      expanded={expanded}
      className={expanded ? "animated-bg" : ""}
    >
      {isExternal && (
        <LoadingWrapper transparent={true}>
          <Row style={{ padding: "0 20px" }}>
            <ExpandableCol>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                currently playing in the external player due to browser
                restrictions
              </div>
            </ExpandableCol>
            <CollapsibleCol>
              <RadioButtons>
                {!isInFavorites(station.id) && (
                  <LikeButton
                    onClick={() => {
                      addToFavorites(station);
                    }}
                  >
                    <img src={buttons.like} />
                  </LikeButton>
                )}

                {isInFavorites(station.id) && (
                  <LikeButton
                    onClick={() => {
                      removeFromFavorites(station.id);
                    }}
                  >
                    <img src={buttons.unlike} />
                  </LikeButton>
                )}

                {collection && collection.stations.length > 0 && (
                  <>
                    <RadioButton
                      src={buttons.previous}
                      onClick={() => selectPreviousStation()}
                      style={{ marginRight: "10px" }}
                    />
                    <RadioButton
                      src={buttons.next}
                      onClick={() => selectNextStation()}
                      style={{ marginRight: "10px" }}
                    />
                    <RadioButton
                      src={buttons.up}
                      // onClick={() => onViewCollection()}
                      onClick={() => setShowList((i) => !i)}
                      rotated={showList}
                    />
                  </>
                )}
              </RadioButtons>
            </CollapsibleCol>
          </Row>
        </LoadingWrapper>
      )}
      {station && !isExternal && (
        <>
          {expanded && (
            <>
              <AppLogo
                src={require("../../assets/logo-white-small.png").default}
              />
              <BackgroundDiv
                background={
                  station.image
                    ? ASSET_FOLDER + "/" + station.image
                    : radioPlaceholder
                }
              />
              <Row style={{ flex: 1 }}>
                <ExpandableCol
                  style={{
                    height: "calc(100vh - 100px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "500px",
                      // height: "500px",
                      maxWidth: "80vw",
                      maxHeight: "80vw",
                      backgroundColor: "aliceblue",
                      padding: "2px",
                      boxShadow: "0 0 5px #777777",
                      border: "solid 2px #ffffff",
                      borderRadius: "10px",
                      zIndex: "12",
                    }}
                  >
                    <ImageWithFallback
                      src={
                        station.image
                          ? ASSET_FOLDER + "/" + station.image
                          : radioPlaceholder
                      }
                      fallbackSrc={radioPlaceholder}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        maxHeight: "80vw",
                        marginBottom: "-9px",
                      }}
                    />
                  </div>

                  <RadioInfoExpanded>
                    <RadioTitleExpanded>{station.name}</RadioTitleExpanded>
                    <RadioLink>{station.homepage}</RadioLink>
                  </RadioInfoExpanded>
                </ExpandableCol>
              </Row>
            </>
          )}

          <Row
            style={{ minHeight: "90px" }}
            className={expanded ? "expanded-player-controls" : ""}
          >
            {!expanded && (
              <>
                <CollapsibleCol style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "64px",
                      height: "64px",
                      backgroundColor: "#ffffff",
                      padding: "2px",
                      boxShadow: "0 0 5px #777777",
                      border: "solid 2px #ffffff",
                      transform: "scale(1.5)",
                      transformOrigin: "bottom left",
                      marginRight: "32px",
                      zIndex: "12",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <ImageWithFallback
                      id="player-image"
                      src={
                        station.image
                          ? ASSET_FOLDER + "/" + station.image
                          : radioPlaceholder
                      }
                      fallbackSrc={radioPlaceholder}
                      style={{
                        width: "100%",
                        maxHeight: "60px",
                      }}
                    />
                  </div>
                </CollapsibleCol>
                <RadioInfo>
                  <RadioTitle title={station.name}>
                    {!isInFavorites(station.id) && (
                      <LikeButton
                        onClick={() => {
                          addToFavorites(station);
                        }}
                      >
                        <img src={buttons.like} />
                      </LikeButton>
                    )}

                    {isInFavorites(station.id) && (
                      <LikeButton
                        onClick={() => {
                          removeFromFavorites(station.id);
                        }}
                      >
                        <img src={buttons.unlike} />
                      </LikeButton>
                    )}
                    {station.name}

                    <InfoButtonInline
                      className="info-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onShowStationInfo(station);
                      }}
                    >
                      i
                    </InfoButtonInline>

                    {pipAvailable && !isPip && (
                      <InfoButtonInline
                        className="pip-button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRequestPip();
                        }}
                      >
                        pop out player
                      </InfoButtonInline>
                    )}

                    {enableCollectionCreator && (
                      <div>
                        <button onClick={() => addToCollection(station)}>
                          add to collection
                        </button>
                        <button onClick={() => dumpCollection()}>
                          dump collection
                        </button>
                        <button onClick={() => resetCollection()}>
                          reset collection
                        </button>
                      </div>
                    )}
                  </RadioTitle>
                  <RadioLink>{station.homepage}</RadioLink>
                </RadioInfo>
              </>
            )}
            <CollapsibleCol>
              {metadata && metadata.StreamTitle && (
                <a
                  target="_blank"
                  href={
                    "https://open.spotify.com/search/" +
                    encodeURIComponent(metadata.StreamTitle)
                  }
                >
                  <SongTitle>
                    <img
                      src={require("../../assets/musical_note.png").default}
                    />
                    {metadata.StreamTitle}
                  </SongTitle>
                </a>
              )}
            </CollapsibleCol>
            {expanded && <ExpandableCol />}
            <CollapsibleCol>
              <RadioButtons>
                <Button factor="primary" onClick={() => setExpanded(!expanded)}>
                  {expanded ? "collapse" : "expand"}
                </Button>

                <div
                  className={
                    "play__button button--play " +
                    (isPlaying ? "button--active" : "")
                  }
                  onClick={() => (isPlaying ? pause() : play())}
                >
                  <div className="button__shape button__shape--one" />
                  <div className="button__shape button__shape--two" />
                </div>

                <div className="slider-container">
                  <input
                    type="range"
                    min="1"
                    max="100"
                    value={volume}
                    onChange={(e) => {
                      setVolume(e.target.value);
                    }}
                    className="slider"
                  />
                </div>
                {collection && collection.stations.length > 0 && (
                  <>
                    <RadioButton
                      src={buttons.previous}
                      onClick={() => selectPreviousStation()}
                      style={{ marginRight: "10px" }}
                    />
                    <RadioButton
                      src={buttons.next}
                      onClick={() => selectNextStation()}
                      style={{ marginRight: "10px" }}
                    />
                    <RadioButton
                      src={buttons.up}
                      // onClick={() => onViewCollection()}
                      onClick={() => setShowList((i) => !i)}
                      rotated={showList}
                    />
                  </>
                )}
              </RadioButtons>
            </CollapsibleCol>
          </Row>
          {/*{station.url_resolved}*/}
          {/*<ReactHowler src={station.url_resolved} playing={true} />*/}

          {!station.isHls && (
            <ReactAudioPlayer
              style={{ display: "none" }}
              ref={audioItemRef}
              src={station.streamUrl}
              controls
              volume={volume / 100}
              onCanPlay={() => {
                play();
              }}
              onPlay={() => {
                setIsPlaying(true);
                setIsLoading(false);
              }}
              onPause={() => {
                setIsPlaying(false);
              }}
              onError={(a, b) => {
                alert("error");
                console.log(a);
                console.log(b);
              }}
              onLoadedMetadata={(a) => {
                console.log(a);
              }}
            />
          )}

          {station.isHls && (
            <ReactHlsPlayer
              style={{ display: "none" }}
              playerRef={audioHlsRef}
              src={station.streamUrl}
              autoPlay={false}
              controls={true}
              volume={volume / 100}
              onCanPlay={() => {
                play();
              }}
              onPlay={() => {
                setIsPlaying(true);
                setIsLoading(false);
              }}
              onPause={() => {
                setIsPlaying(false);
              }}
            />
          )}
        </>
      )}
      {!station && !isExternal && (
        <LoadingWrapper transparent={true}>
          no station selected.
          {enableCollectionCreator && (
            <div>
              <button onClick={() => dumpCollection()}>dump collection</button>
              <button onClick={() => resetCollection()}>
                reset collection
              </button>
            </div>
          )}
        </LoadingWrapper>
      )}

      <AnimatePresence>
        {showList && (
          <motion.div
            className="playlist-container"
            initial={{ bottom: -400 }}
            animate={{ bottom: 90 }}
            exit={{ bottom: -400 }}
          >
            <PerfectScrollbar>
              <div className="hide-last-item-margin">
                {collection.stations.map((s, idx) => {
                  return (
                    <ListItem
                      onClick={() => selectStationByIndex(idx)}
                      selected={station && s && station.id === s.id}
                    >
                      <Row>
                        <CollapsibleCol style={{ ...centeredStyle }}>
                          <div
                            style={{
                              width: "48px",
                              height: "48px",
                              ...centeredStyle,
                            }}
                          >
                            <ImageWithFallback
                              src={
                                s.image
                                  ? ASSET_FOLDER + "/" + s.image
                                  : radioPlaceholder
                              }
                              fallbackSrc={radioPlaceholder}
                              style={{
                                width: "48px",
                                borderRadius: "5px",
                                maxHeight: "48px",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </CollapsibleCol>
                        <ExpandableCol style={{ ...centeredStyle }}>
                          {s.name}
                        </ExpandableCol>
                      </Row>
                    </ListItem>
                  );
                })}
              </div>
            </PerfectScrollbar>
          </motion.div>
        )}
      </AnimatePresence>

      {isLoading && (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      )}
    </PlayerWrapper>
  );
};

export default Player;

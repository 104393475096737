import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContext } from "./common/toastContext/toastContext";
import { LoadingContext } from "./common/loadingContext/loadingContext";
import { SettingsContext } from "./common/settingsContext/settingsContext";

Sentry.init({
  dsn:
    "https://652991da88914bb6b9595cac073217be@o434567.ingest.sentry.io/6053191",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

const disableConsoleLog = () => {
  console.log('%cThe Radiotal web app is in beta phase. If you encounter any issue, please contact us.', 'font-size: 20px; font-weight: bold; color: #f9ca24; padding: 20px; border-radius: 5px; background: #140040');

  // @ts-ignore
  if (!window.console) window.console = {};
  const methods = ["log", "debug", "warn", "info"];
  for (let i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

// disable console.log
disableConsoleLog();

ReactDOM.render(
  <React.StrictMode>
    <ToastContext>
      <LoadingContext>
        <SettingsContext>
          <Router>
            <App />
          </Router>
        </SettingsContext>
      </LoadingContext>
    </ToastContext>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
